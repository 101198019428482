import classNames from 'classnames';
import React from 'react';

import {DiscoveryUnit, DiscoveryUnitItem} from '@udemy/discovery-api';
import {FunnelLogContextProvider} from '@udemy/funnel-tracking';
import {useI18n} from '@udemy/i18n';
import {
    AlternateHeadline,
    BundleUnit,
    DiscoveryUnitsContainer,
    DiscoveryUnitsContainerStore,
} from '@udemy/react-discovery-units';
import {InternalDiscoveryUnitsContainerProps} from '@udemy/react-discovery-units/dist/@types/discovery-units-container/discovery-units-container.react-component';
import {ErrorBoundary} from '@udemy/react-error-boundary';

import {
    SkeletonCourseCarousel,
    SkeletonCourseList,
    SkeletonTitle,
} from 'udemy-django-static/js/browse/components/discovery-units-container/discovery-units-skeletons.react-component';
import {StudentQuoteUnit} from 'udemy-django-static/js/browse/components/student-quote/student-quote-unit.react-component';
import {UI_REGION} from 'udemy-django-static/js/browse/ui-regions';

import {DiscoveryListContainer} from '../../category/components/browse/discovery-list-container/discovery-list-container.react-component';
import {RefundNotice} from '../../category/components/browse/notices/refund-notice.react-component';
import {TopCompaniesNotice} from '../../category/components/browse/notices/top-companies-notice.react-component';
import {PRESET_FREE, SOURCE_PAGE_TYPE} from '../constants';
import {FreeTopicProps} from '../free-topic.react-component';
import styles from '../topic.module.less';
import {TopicPageExperimentVariants, TopicProps} from '../topic.react-component';
import {isOrgTopicPage, isSubsTopicPage, TopicPageType} from '../types/page';
import {getPageObjectIdFromTopic} from '../utils';
import {
    AVAILABLE_UNIT_TYPES,
    AVAILABLE_UNIT_VIEW_TYPES,
    getAvailableDiscoveryUnitComponentsAndProps,
} from './topic-discovery-units';

interface TopicDiscoveryListContainerWrapperProps extends TopicProps {
    pageType: TopicPageType;
    presetFilters?: {price: string[]};
    handlePageChange?: () => void;
}

export const TopicDiscoveryListContainerWrapper = (
    props: TopicDiscoveryListContainerWrapperProps,
) => {
    const {
        topic,
        pageType,
        subsCollectionIds,
        isMobile,
        highlightedCourseReview,
        presetValue,
        presetFilters,
        secondaryDescription,
        handlePageChange,
        useVerticalMobileBrowseLayout,
        fullQuickViewBoxFeature,
    } = props;

    const courseCardSize = isMobile ? 'small' : 'large';

    const getSecondaryHeadingText = () => {
        if (presetValue === PRESET_FREE) {
            return gettext('All %(topicTitle)s tutorials');
        }
        return gettext('All %(topicTitle)s courses');
    };

    const renderDiscoveryListPromoComponents = () => {
        return [
            <FunnelLogContextProvider
                key="bundle-unit"
                data-item-index={9}
                context={SOURCE_PAGE_TYPE}
                subcontext={'learning-pack'}
                data-purpose="bundle-unit"
            >
                <BundleUnit
                    pageType="topic-bundle"
                    pageObjectId={topic.id}
                    titleTypography={isMobile ? 'ud-heading-lg' : 'ud-heading-xl'}
                />
            </FunnelLogContextProvider>,
            !isSubsTopicPage(pageType) && (
                <div
                    key="top-companies-notice"
                    data-item-index={3}
                    data-purpose="top-companies-notice"
                >
                    <TopCompaniesNotice refParam="right-rail" />
                </div>
            ),
            <StudentQuoteUnit
                key="student-quote-unit"
                data-item-index={6}
                topicTitle={topic.name}
                courseReview={highlightedCourseReview}
                data-purpose="student-quote-unit"
            />,
        ];
    };

    const verticalMobileBrowseLayoutExperimentProps = {
        showAddToCartInCourseCard: true,
        disableMobileAddToCartRedirect: true,
        cartButtonTextGoToCart: gettext('In your cart'),
        cartButtonDisableOnGoToCart: true,
        allowAddToCartSuccessModal: false,
    };

    return (
        <>
            <AlternateHeadline
                data-purpose="all-topic-courses"
                title={interpolate(getSecondaryHeadingText(), {topicTitle: topic.name}, true)}
                titleTag="h2"
                secondaryText={!isOrgTopicPage(pageType) ? secondaryDescription : undefined}
                layoutVariant={isMobile ? 'compact' : 'default'}
                className={styles['secondary-heading']}
            />
            {!isSubsTopicPage(pageType) && <RefundNotice className={styles['refund-notice']} />}
            <DiscoveryListContainer
                pageType={pageType}
                pageSize={16}
                pageObjectId={getPageObjectIdFromTopic(topic)}
                presetFilters={presetFilters}
                courseCardSize={courseCardSize}
                onPageChange={handlePageChange}
                subsCollectionIds={subsCollectionIds}
                fullQuickView={fullQuickViewBoxFeature}
                query={topic.name}
                {...(!!isMobile && useVerticalMobileBrowseLayout
                    ? {...verticalMobileBrowseLayoutExperimentProps}
                    : {})}
            >
                {!isOrgTopicPage(pageType) && renderDiscoveryListPromoComponents()}
            </DiscoveryListContainer>
        </>
    );
};

export interface TopicDiscoveryUnitsContainerWrapperProps
    extends Pick<TopicProps, 'enrollmentStat' | 'topic' | 'isMobile'>,
        TopicPageExperimentVariants {
    children?: React.ReactNode;
    store?: DiscoveryUnitsContainerStore;
    pageType: string;
    discoveryUnitFilter: (unit: Partial<DiscoveryUnit>) => boolean;
    disableInfiniteScroll?: boolean;
    isStaticRender?: boolean;
    units?: DiscoveryUnit[];
    fetchOptions?: InternalDiscoveryUnitsContainerProps['fetchOptions'];
}
export const TopicDiscoveryUnitsContainerWrapper = (
    props: TopicDiscoveryUnitsContainerWrapperProps,
) => {
    const {
        useStandardizeMobileBrowseCarousel,
        showSubheadingsInCatSubcatTopicRecommendations,
        useVerticalMobileBrowseLayout,
        //----------
        topic,
        isMobile,
        store,
        pageType,
        children,
        discoveryUnitFilter,
        fetchOptions,
        disableInfiniteScroll,
        units,
        isStaticRender,
    } = props;

    const {gettext} = useI18n();
    const useMultirowLayout = isMobile && !useStandardizeMobileBrowseCarousel;
    const useStackedMobileLayout = !!isMobile && useVerticalMobileBrowseLayout;

    const getUnitComponentAndProps = (unit: Partial<DiscoveryUnit<DiscoveryUnitItem>>) => {
        const singleCourseUnitExperimentProps = {
            allowAddToCartSuccessModal: false,
            showAddToCartInCourseCard: true,
            showQuickViewBox: false,
            disableMobileAddToCartRedirect: true,
            cartButtonTextGoToCart: gettext('In your cart'),
            cartButtonDisableOnGoToCart: true,
        };
        const stackedMobileLayoutExperimentProps = {
            layout: 'singlecol',
            disableInfiniteScroll: true,
            showInstructorDetails: true,
            maxCards: 12,
            maxCardsPerStack: 3,
            ...singleCourseUnitExperimentProps,
        };

        const unitComponentPropsByUnitType = {
            [AVAILABLE_UNIT_TYPES.BESTSELLER_LABELS]: {
                titleTypography: isMobile ? 'ud-heading-lg' : 'ud-heading-xl',
            },
            [AVAILABLE_UNIT_TYPES.OCCUPATION]: {
                fullWidth: isMobile,
                showPager: !isMobile,
                layout: useMultirowLayout ? 'multirow' : 'singlerow',
            },
            [AVAILABLE_UNIT_TYPES.ALGORITHM_BASED_ASSESSMENTS_WITHOUT_FILTER]: {
                title: gettext('Evaluate your skills with assessments'),
                subtitle: gettext(
                    'Identify your proficiency level and get content recommendations based on your results.',
                ),
                uiRegion: UI_REGION.ASSESSMENT_UNIT_TOPIC,
                sourcePageType: SOURCE_PAGE_TYPE,
                sourcePageId: getPageObjectIdFromTopic(topic),
            },
            [AVAILABLE_UNIT_TYPES.ALGORITHM_BASED_LABS_WITHOUT_FILTER]: {
                title: gettext('Get hands-on practice with labs'),
                subtitle: gettext(
                    'Sharpen your technical skills with access to risk-free environments and real-world projects.',
                ),
                sourcePageType: SOURCE_PAGE_TYPE,
                sourcePageId: getPageObjectIdFromTopic(topic),
                uiRegion: UI_REGION.LAB_UNIT_TOPIC,
            },
            [AVAILABLE_UNIT_TYPES.TOP_INSTRUCTOR]: {
                componentName: 'popular_instructors_unit',
                alternateHeadline:
                    showSubheadingsInCatSubcatTopicRecommendations?.addPopularInstructorsSubheading
                        ? {
                              title: unit.title,
                              secondaryText: gettext(
                                  'These real-world experts are highly rated by learners like you.',
                              ),
                              secondaryTextClass: styles['alternate-headline__subtitle'],
                          }
                        : {},
                showTitle:
                    !showSubheadingsInCatSubcatTopicRecommendations?.addPopularInstructorsSubheading,
            },
            [AVAILABLE_UNIT_VIEW_TYPES.HORIZONTAL_TABBED]: {
                className: classNames('component-margin', {
                    [styles['primary-unit']]: isMobile,
                }),
                compact: isMobile,
                gridCols: !isMobile ? 4 : undefined,
                renderExperimentalCourseCards: false,
                // Hack to make statically-rendered course cards larger and ensure they're considered the LCP element.
                courseCardProps: isStaticRender
                    ? {className: styles['expanded-course-card']}
                    : undefined,
                alternateHeadline:
                    showSubheadingsInCatSubcatTopicRecommendations?.addNewBeginnerSubheading
                        ? {
                              title: unit.title,
                              secondaryText: gettext(
                                  'Explore courses from experienced, real-world experts.',
                              ),
                              secondaryTextClass: styles['alternate-headline__subtitle'],
                          }
                        : {},
                showTitle:
                    !showSubheadingsInCatSubcatTopicRecommendations?.addNewBeginnerSubheading,
                ...(useStackedMobileLayout && stackedMobileLayoutExperimentProps),
            },
            [AVAILABLE_UNIT_TYPES.FEATURED_COURSE]:
                showSubheadingsInCatSubcatTopicRecommendations?.addFeaturedCoursesSubheading
                    ? {
                          alternateHeadline: {
                              title: unit.title,
                              secondaryText: gettext(
                                  'Many learners enjoyed this highly rated course for its engaging content.',
                              ),
                              secondaryTextClass: styles['alternate-headline__subtitle'],
                          },
                          showTitle: false,
                          ...(useStackedMobileLayout && singleCourseUnitExperimentProps),
                      }
                    : {
                          ...(useStackedMobileLayout && singleCourseUnitExperimentProps),
                      },
            [AVAILABLE_UNIT_VIEW_TYPES.DEFAULT]: {
                fullWidth: isMobile,
                showPager: !isMobile,
                layout: useMultirowLayout ? 'multirow' : 'singlerow',
                titleTypography: isMobile ? 'ud-heading-lg' : undefined,
                showSubtitle: true,
                carouselProps: {allowScroll: isMobile},
                gridCols: !isMobile ? 4 : undefined,
                renderExperimentalCourseCards: false,
                ...(useStackedMobileLayout && stackedMobileLayoutExperimentProps),
            },
        };

        return getAvailableDiscoveryUnitComponentsAndProps(
            unit as DiscoveryUnit,
            unitComponentPropsByUnitType,
        );
    };

    return (
        <ErrorBoundary>
            <DiscoveryUnitsContainer
                alwaysShowInjectedComponents={true}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                pageType={pageType}
                pageObjectId={getPageObjectIdFromTopic(topic)}
                getUnitComponentAndProps={getUnitComponentAndProps}
                disableInfiniteScroll={disableInfiniteScroll}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                filter={(unit) => discoveryUnitFilter(unit)}
                store={store}
                units={units}
                deviceType={isMobile ? 'mobile' : 'desktop'}
                fetchOptions={fetchOptions}
            >
                {children}
            </DiscoveryUnitsContainer>
        </ErrorBoundary>
    );
};

export const TopicDiscoveryUnitsContainerWrapperLoader = ({isMobile}: {isMobile: boolean}) => {
    return (
        <>
            <SkeletonTitle data-item-index={0} />
            <SkeletonCourseCarousel courseCount={isMobile ? 3 : 6} />
            <SkeletonCourseList courseCount={4} />
            <SkeletonCourseList courseCount={4} />
        </>
    );
};

export interface FreeTopicDiscoveryUnitsContainerWrapperProps
    extends Pick<FreeTopicProps, 'topic' | 'isMobile'>,
        TopicPageExperimentVariants {
    children?: React.ReactNode;
    pageType: string;
    discoveryUnitFilter: (unit: Partial<DiscoveryUnit>) => boolean;
    fetchOptions?: InternalDiscoveryUnitsContainerProps['fetchOptions'];
}
export const FreeTopicDiscoveryUnitsContainerWrapper = (
    props: FreeTopicDiscoveryUnitsContainerWrapperProps,
) => {
    const {gettext, interpolate} = useI18n();
    const {
        showSubheadingsInCatSubcatTopicRecommendations,
        topic,
        isMobile,
        pageType,
        children,
        discoveryUnitFilter,
        fetchOptions,
    } = props;

    const getUnitComponentAndProps = (unit: Partial<DiscoveryUnit<DiscoveryUnitItem>>) => {
        const unitComponentPropsByUnitType = {
            [AVAILABLE_UNIT_TYPES.BESTSELLER_LABELS]: {
                titleTypography: isMobile ? 'ud-heading-lg' : 'ud-heading-xl',
            },
            [AVAILABLE_UNIT_VIEW_TYPES.HORIZONTAL_TABBED]: {
                alternateHeadline: {
                    title: interpolate(
                        gettext('Get more with paid %(topic)s courses'),
                        {topic: props.topic.name},
                        true,
                    ),
                    secondaryText: gettext(
                        'Enroll in our in-depth courses from top-rated instructors',
                    ),
                },
                showTitle: false,
            },
            [AVAILABLE_UNIT_TYPES.TOP_INSTRUCTOR]:
                showSubheadingsInCatSubcatTopicRecommendations?.addPopularInstructorsSubheading
                    ? {
                          alternateHeadline: {
                              title: unit.title,
                              secondaryText: gettext(
                                  'These real-world experts are highly rated by learners like you.',
                              ),
                              secondaryTextClass: styles['alternate-headline__subtitle'],
                          },
                          showTitle: false,
                      }
                    : {},
            [AVAILABLE_UNIT_TYPES.FEATURED_COURSE]:
                showSubheadingsInCatSubcatTopicRecommendations?.addFeaturedCoursesSubheading
                    ? {
                          alternateHeadline: {
                              title: unit.title,
                              secondaryText: gettext(
                                  'Many learners enjoyed this highly rated course for its engaging content.',
                              ),
                              secondaryTextClass: styles['alternate-headline__subtitle'],
                          },
                          showTitle: false,
                      }
                    : {},
            [AVAILABLE_UNIT_VIEW_TYPES.DEFAULT]: {
                fullWidth: isMobile,
                showTitle: true,
                showPager: !isMobile,
                layout: isMobile ? 'multirow' : 'singlerow',
                allowScroll: isMobile,
            },
        };

        return getAvailableDiscoveryUnitComponentsAndProps(
            unit as DiscoveryUnit,
            unitComponentPropsByUnitType,
        );
    };

    return (
        <ErrorBoundary>
            <DiscoveryUnitsContainer
                alwaysShowInjectedComponents={true}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                pageType={pageType}
                pageObjectId={getPageObjectIdFromTopic(topic)}
                getUnitComponentAndProps={getUnitComponentAndProps}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                filter={(unit) => discoveryUnitFilter(unit)}
                deviceType={isMobile ? 'mobile' : 'desktop'}
                fetchOptions={fetchOptions}
            >
                {children}
            </DiscoveryUnitsContainer>
        </ErrorBoundary>
    );
};
