import {Topic} from 'src/generated/graphql';

export interface ComparisonModuleStore {
    searchParams: URLSearchParams;
    unit: {
        title: string;
        content: {
            is_active: boolean;
            text: string;
        }[];
    }[];
}

export class ComparisonModuleStore {
    constructor(topicData: Topic, gettext: (s: string) => string) {
        this.searchParams = new URLSearchParams(`q=${topicData.name}&p=1&price=price-free`);
        this.unit = [
            {
                title: gettext('Free courses'),
                content: [
                    {
                        is_active: true,
                        text: gettext('Online video content'),
                    },
                    {
                        is_active: false,
                        text: gettext('Certificate of completion'),
                    },
                    {
                        is_active: false,
                        text: gettext('Instructor Q&A'),
                    },
                    {
                        is_active: false,
                        text: gettext('Instructor direct message'),
                    },
                ],
            },
            {
                title: gettext('Paid courses'),
                content: [
                    {
                        is_active: true,
                        text: gettext('Online video content'),
                    },
                    {
                        is_active: true,
                        text: gettext('Certificate of completion'),
                    },
                    {
                        is_active: true,
                        text: gettext('Instructor Q&A'),
                    },
                    {
                        is_active: true,
                        text: gettext('Instructor direct message'),
                    },
                ],
            },
        ];
    }
}
