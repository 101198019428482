import {Tracker} from '@udemy/event-tracking';

import {LAB_TRACKING_EVENTS} from './constants';

export const sendLabDiscoveryCardClickEvent = ({
    labId,
    courseId,
    uiRegion,
    trackingId,
    sourcePageId,
    sourcePageType,
}) => {
    Tracker.publishEvent(
        new LAB_TRACKING_EVENTS.DISCOVERY_CARD_CLICK({
            labId,
            courseId,
            uiRegion,
            trackingId,
            sourcePageId,
            sourcePageType,
        }),
    );
};
