// eslint-disable-next-line import/prefer-default-export
export const CONTEXT_TYPES = {
    SEARCH: 'ufb_search',
    ALL_COURSES: 'ufb_all_courses',
    CLP: 'ufb_clp',
    UFB_COURSE_TAKING: 'ufb_ct',
    COURSE_TAKING: 'ct',
    COURSE_QUICK_VIEW_BOX: 'ufb_cqvb',
    MANAGE_COURSES: 'ufb_manage_courses',
    MY_LEARNING_UNIT: 'ufb_my_learning_unit',
    COURSE_INSIGHTS: 'ufb_course_insights',
    PATH_INSIGHTS: 'ufb_path_insights',
    SKILL_DETAILS: 'ufb_skill_details',
    LOGGED_IN_HOMEPAGE: 'org_logged_in_homepage',
    ANALYTICS_ASSIGNED_LEARNING_PAGE: 'AssignedLearning',
    LEARNING_PATH_LIST_PAGE: 'LearningPathsListPage',
    LEARNING_PATH: 'LearningPath',
    LEARNING_PATH_INSIGHTS: 'pathInsights',
    AUTO_ASSIGN_RULES_PAGE: 'AssignmentRules',
    MY_COURSES: 'my_courses',
    MX_MY_LEARNING_UNIT: 'mx_my_learning_unit',
    LAB_LIST_PAGE: 'lab_list_page',
    LAB_OVERVIEW_PAGE: 'lab_overview_page',
    LAB_ASSIGNED_LEARNING_PAGE: 'lab_assigned_learning_page',
    LAB_USER_DETAIL_PAGE: 'lab_user_detail_page',
    ASSESSMENT_LIST_PAGE: 'assessment_list_page',
    ASSESSMENT_OVERVIEW_PAGE: 'assessment_overview_page',
    ASSESSMENT_USER_DETAILS_PAGE: 'assessment_user_details_page',
    COURSE_USER_DETAILS_PAGE: 'course_user_details_page',
    LEARNING_PATH_USER_DETAILS_PAGE: 'learning_path_user_details_page',
    COURSE_UPDATES: 'course_updates',
    OPEN_BADGE_DETAIL_PAGE: 'open_badge_detail_page',
};
