import {DiscoveryUnit} from '@udemy/discovery-api';
import {
    CourseUnit,
    PopularTopicsUnit,
    SingleCourseUnit,
    SkillsHubUnit,
} from '@udemy/react-discovery-units';
import {PopularInstructorsUnit} from '@udemy/react-discovery-units';
import {udSentry} from '@udemy/sentry';

import {AssessmentUnit} from 'udemy-django-static/js/browse/components/discovery-units/assessment-unit/assessment-unit.react-component';
import {LabUnit} from 'udemy-django-static/js/browse/components/discovery-units/lab-unit/lab-unit.react-component';
import OccupationUnit from 'udemy-django-static/js/browse/components/discovery-units/occupation-unit/occupation-unit.react-component';

import {withAboveTheFoldProvider} from '../../../components/above-the-fold/with-above-the-fold-provider.react-component';

export const SingleCourseUnitAboveTheFold = withAboveTheFoldProvider(SingleCourseUnit);
export const SkillsHubUnitAboveTheFold = withAboveTheFoldProvider(SkillsHubUnit);

/**
 * Below units are units that are available to be rendered in any Topic Page. Including MX, Subs, UB, Free etc.
 * Note that, these are the units that are coming from the Discovery API context
 * @remarks
 *
 * IF YOU ARE ADDING A NEW UNIT, PLEASE MAKE SURE TO ADD IT TO THE BELOW LIST AND MAKE SURE THAT THE UNIT IS COMING
 * FROM THE DISCOVERY API CONTEXT AS WELL. OTHERWISE, IT WILL NOT BE RENDERED.
 */
export const AVAILABLE_UNIT_TYPES = {
    ALGORITHM_BASED_ASSESSMENTS_WITHOUT_FILTER: 'algorithm_based_assessments_without_filter',
    ALGORITHM_BASED_WITH_FILTERS: 'algorithm_based_with_filters',
    BESTSELLER_LABELS: 'bestseller_labels',
    OCCUPATION: 'occupation',
    ALGORITHM_BASED_LABS_WITHOUT_FILTER: 'algorithm_based_labs_without_filter',
    FEATURED_COURSE: 'featured_course',
    TOP_INSTRUCTOR: 'top_instructor',
    BESTSELLER: 'bestseller',
};

/**
 * Below view types are view types that are available to be rendered in any Topic Page.
 * For some components, we're prioritizing view types over unit types such as Skills Hub Unit.
 *
 * IF YOU ARE ADDING A NEW UNIT, PLEASE MAKE SURE TO ADD IT TO THE BELOW LIST.
 */
export const AVAILABLE_UNIT_VIEW_TYPES = {
    HORIZONTAL_TABBED: 'horizontal_tabbed',
    DEFAULT: 'default',
};

export type AvailableDiscoveryUnitType =
    (typeof AVAILABLE_UNIT_TYPES)[keyof typeof AVAILABLE_UNIT_TYPES];
export type AvailableDiscoveryUnitViewType =
    (typeof AVAILABLE_UNIT_VIEW_TYPES)[keyof typeof AVAILABLE_UNIT_VIEW_TYPES];

/**
 * Below mappings are currently being rendered on any Topic Page.
 *
 * Previously, we were relying on DiscoveryUnitsContainer to pick correct container but we've removed that logic.
 * Now consumers provide the correct component to be rendered to DiscoveryUnitsContainer.
 *
 * Currently, we have two level unit to component mapping:
 *  - Unit Type to Component (L1)
 *  - Unit Type
 *       |___ Unit View Type to Component (L2)
 *
 * @remarks
 * IF YOU ARE ADDING A NEW UNIT, PLEASE MAKE SURE TO ADD IT TO THE BELOW MAPPINGS.
 */
export const getTopicDiscoveryUnitComponentByUnitTypes = (
    unitType: AvailableDiscoveryUnitType,
    unitViewType?: AvailableDiscoveryUnitViewType,
) => {
    switch (unitType) {
        case AVAILABLE_UNIT_TYPES.BESTSELLER_LABELS:
            return PopularTopicsUnit;
        case AVAILABLE_UNIT_TYPES.OCCUPATION:
            return OccupationUnit;
        case AVAILABLE_UNIT_TYPES.ALGORITHM_BASED_LABS_WITHOUT_FILTER:
            return LabUnit;
        case AVAILABLE_UNIT_TYPES.ALGORITHM_BASED_ASSESSMENTS_WITHOUT_FILTER:
            return AssessmentUnit;
        case AVAILABLE_UNIT_TYPES.FEATURED_COURSE:
            return SingleCourseUnitAboveTheFold;
        case AVAILABLE_UNIT_TYPES.TOP_INSTRUCTOR:
            return PopularInstructorsUnit;
        case AVAILABLE_UNIT_TYPES.ALGORITHM_BASED_WITH_FILTERS:
            return CourseUnit;
        case AVAILABLE_UNIT_TYPES.BESTSELLER:
            switch (unitViewType) {
                case AVAILABLE_UNIT_VIEW_TYPES.HORIZONTAL_TABBED:
                    return SkillsHubUnitAboveTheFold;
                case AVAILABLE_UNIT_VIEW_TYPES.DEFAULT:
                    return CourseUnit;
            }
    }
};

/**
 * Get available discovery unit components and props for DiscoveryUnitContainer
 * @param unit - Discovery Unit to be mapped to a component
 * @param unitsProps - Props for the unit components to be passed. Otherwise it will be an empty object
 */
export const getAvailableDiscoveryUnitComponentsAndProps = (
    unit: DiscoveryUnit,
    unitsProps?: Record<
        AvailableDiscoveryUnitType | AvailableDiscoveryUnitViewType,
        Record<string, unknown>
    >,
) => {
    const injectDefaultComponentProps = (props: Record<string, unknown> = {}) => {
        return {
            ...props,
            /**
             * This is a necessary addition to make sure that the components are rendered with the correct margin.
             * Current discovery units container component margin is working if current component has above sibling
             * that has again, component-margin class.
             *
             * Since we're injecting components from outside to discovery units container, className are not being matched.
             * For example like:
             *
             * <div class="component-margin"/> ----------------------------------> Injected by us
             * <div class="discovery-units-container_component-margin-XYZY"/> ---> No margin, but it should have
             * <div class="component-margin"/> ----------------------------------> Injected by us
             * <div class="discovery-units-container_component-margin-XYZY"/> ---> No margin, but it should have
             *
             * So rather than using mixed class names and seeing this problems, for now, we're overriding the className
             * of all of the units that we're passing to discovery units container.
             *
             * TODO: Please fix this on the Discovery Units Container side. Consumer either rely on it or they should
             * provide margins all to get all the time.
             */
            className: props?.className ?? 'component-margin',
        };
    };

    const componentFromUnitTypeAndView = getTopicDiscoveryUnitComponentByUnitTypes(
        unit.type,
        unit.view_type,
    );

    if (componentFromUnitTypeAndView) {
        const componentProps = injectDefaultComponentProps(
            unitsProps?.[unit.type] ?? unitsProps?.[unit.view_type ?? ''],
        );
        return {
            component: componentFromUnitTypeAndView,
            componentProps: componentProps,
        };
    }

    udSentry.captureException(
        new Error(
            `Missing component for unit type: ${unit.type} & view type: ${unit.view_type} for topic page}`,
        ),
    );

    /**
     * This is mainly for unblocking the rendering of the page. Currently, discovery units is just throwing an
     * error and this is the only way to prevent it, easily at least.
     *
     * TODO: Fix this on the Discovery Units Container side. And make above configurable for consumers
     */
    return {
        component: () => null,
        componentProps: {},
    };
};
