import classNames from 'classnames';
import React from 'react';

import {useI18n} from '@udemy/i18n';
import PeopleIcon from '@udemy/icons/dist/people.ud-icon';
import {safelySetInnerHTML} from '@udemy/shared-utils';

import styles from './topic-enrollment-stats.module.less';

interface TopicEnrollmentStatsProps {
    enrollmentStat: string;
    className?: string;
}

export function TopicEnrollmentStats({enrollmentStat, className}: TopicEnrollmentStatsProps) {
    const {gettext, interpolate} = useI18n();
    return (
        <p
            className={classNames(className, styles.container)}
            data-purpose="topic-enrollment-stats"
        >
            <PeopleIcon label={false} size="small" />
            <span
                className={classNames('ud-text-sm', styles.text)}
                {...safelySetInnerHTML({
                    descriptionOfCaller: 'topic-enrollment-stats:num-students-learning',
                    dataPurpose: 'Number of learners',
                    html: interpolate(
                        gettext('%(numStudents)s learners'),
                        {
                            numStudents: enrollmentStat,
                        },
                        true,
                    ),
                })}
            />
        </p>
    );
}
