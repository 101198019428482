import {useI18n} from '@udemy/i18n';
import {Image} from '@udemy/react-core-components';
import {useUDData, useUDLink} from '@udemy/ud-data';
import classNames from 'classnames';
import React from 'react';

import styles from './student-quote.module.less';

export interface CourseReviewProps {
    course: {
        title: string;
        url: string;
    };
    text: string;
}

interface StudentQuoteProps {
    courseReview: CourseReviewProps;
    topicTitle: string;
}

export const StudentQuote = ({courseReview, topicTitle}: StudentQuoteProps) => {
    const {gettext, interpolate} = useI18n();
    const udData = useUDData();
    const udLink = useUDLink();

    const title = interpolate(gettext('From a Udemy %(topicTitle)s student'), {topicTitle}, true);

    return (
        <div className={styles['student-quote']}>
            <Image
                src={udLink.toStorageStaticAsset(
                    'browse_components/student-quote-unit/quote.svg',
                    udData,
                )}
                alt=""
                width={40}
                height={36}
                className={styles.quote}
            />
            <div className={styles.content}>
                <h2 className={classNames('ud-heading-lg', styles.title)}>{title}</h2>
                <div data-purpose="course-review" className={classNames('ud-text-md', styles.text)}>
                    {courseReview.text}
                </div>
                <a
                    className="ud-text-sm ud-link-underline"
                    href={courseReview.course.url}
                    title={courseReview.course.title}
                >
                    {courseReview.course.title}
                </a>
            </div>
        </div>
    );
};
