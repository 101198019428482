import PlayIcon from '@udemy/icons/dist/play.ud-icon';
import {Duration} from '@udemy/react-date-time-components';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';
import React from 'react';

import {LocalizedHtml} from '@udemy/i18n';
import {TrackImpression, TrackingData, TrackingContext} from '@udemy/event-tracking';

import {IS_DEFAULT_PLAYING} from './constants';
import styles from './video-card.module.less';

function attachAutoplayParam(url: string) {
    const [urlPart, queryString] = url.split('?');
    const searchParams = new URLSearchParams(queryString);
    searchParams.set(IS_DEFAULT_PLAYING, '');
    return `${urlPart}?${searchParams.toString()}`;
}

export interface VideoData {
    url?: string;
    enroll_url?: string;
    learn_url?: string;
    title: string;
    primary_topic_title: string;
    content_length: number;
}

interface VideoCardProps {
    data: VideoData;
    showTopicTitle?: boolean;
    index?: number;
    trackingData?: TrackingData;
    trackingContext?: TrackingContext & {
        backendSource: string, 
        trackImpressionFunc(data: any, context: any): void;
    }
    isConsumerSubsSubscriber?: boolean;
    onAction?(): void;
}

export const VideoCard = inject('trackingContext')(
    observer(
        ({
            data,
            showTopicTitle = false,
            index = -1,
            trackingContext,
            trackingData,
            onAction,
            isConsumerSubsSubscriber,
        }: VideoCardProps) => {
            let url = data.url;
            const lectureUrl = isConsumerSubsSubscriber ? data.learn_url : data.enroll_url;
            if ((!url || isConsumerSubsSubscriber) && lectureUrl) {
                url = attachAutoplayParam(lectureUrl);
            }

            function trackImpression() {
                if (trackingContext && trackingData) {
                    const {backendSource, trackImpressionFunc} = trackingContext;
                    const context = {
                        index,
                        backendSource,
                    };
                    trackImpressionFunc({item: trackingData}, context);
                }
            }

            return (
                <TrackImpression trackFunc={trackImpression}>
                    <a
                        href={url}
                        onClick={onAction}
                        data-purpose="card-url"
                        className={styles.wrapper}
                    >
                        <PlayIcon label={false} size="xlarge" className={styles['play-icon']} />
                        <div
                            className={classNames('ud-heading-sm', styles.title)}
                            data-purpose="card-title"
                        >
                            {data.title}
                        </div>
                        <div className={classNames('ud-text-xs', styles['bottom-info-container'])}>
                            {showTopicTitle && (
                                <div data-purpose="topic" className={styles.topic}>
                                    {data.primary_topic_title}
                                </div>
                            )}
                            <LocalizedHtml
                                dataPurpose="video-duration"
                                html={gettext('<span class="duration">%(duration)s</span> video')}
                                interpolate={{
                                    duration: (
                                        <Duration
                                            numSeconds={data.content_length}
                                            precision={Duration.PRECISION.MINUTES}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </a>
                </TrackImpression>
            );
        },
    ),
);
