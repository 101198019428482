import Head from 'next/head';
import {useRouter} from 'next/router';
import React from 'react';

import {HeadSEO} from '@udemy/head-seo';
import {ExperimentationProvider} from '@udemy/react-experimentation-provider';
import {SubscriptionPlanProductType, SubscriptionPlansProvider} from '@udemy/subscription-graphql';

import {getLayout} from 'src/components/layout';
import {SchemaMarkup} from 'src/components/schema-markup/schema-markup.react-component';
import {SubscriptionCTAExperimentProvider} from 'src/contexts/subscription-cta-experiment-context';
import {useCanonicalUrl} from 'src/hooks/useCanonicalUrl';
import {META_DEFAULT_BASE_URL} from 'src/lib/constants';
import UIFeedback from 'udemy-django-static/js/ui-feedback/ui-feedback.react-component';

import {useSetupNavigation} from '../../hooks/use-setup-navigation';
import {
    TopicPageExperimentationFeatures,
    TopicWrapper,
    TopicWrapperProps,
} from './topic-wrapper.react-component';

export type TopicPageFeatureProps = TopicWrapperProps;

export function TopicPageFeature(props: TopicPageFeatureProps) {
    const router = useRouter();
    const {url: canonicalUrl} = useCanonicalUrl(['p'], META_DEFAULT_BASE_URL);
    useSetupNavigation();

    const {schema_markup, metaProps} = props;

    const robotRules =
        metaProps.seoRobotRules && metaProps.seoRobotRules.length > 0
            ? metaProps.seoRobotRules
            : undefined;

    return (
        <>
            <HeadSEO
                title={metaProps.seoTitle}
                ogTitle={metaProps.seoTitle}
                description={metaProps.seoDescription}
                ogDescription={metaProps.seoDescription}
                ogType="video_lecture"
                ogLocale={process.env.I18N_PATH_TO_BUNDLE_MAP[props.locale]}
                canonicalUrl={canonicalUrl}
                assetPath={process.env.ASSET_PREFIX}
                headWrapper={Head}
                robots={robotRules}
            />
            {schema_markup && <SchemaMarkup schemaMarkup={schema_markup} />}
            <UIFeedback />
            <ExperimentationProvider features={TopicPageExperimentationFeatures}>
                <SubscriptionCTAExperimentProvider
                    context={{
                        showCancelAnytime: true,
                    }}
                >
                    <SubscriptionPlansProvider
                        subscriptionPlanQueryVariables={[
                            {
                                filters: {
                                    productType: SubscriptionPlanProductType.Consumersubscription,
                                },
                                marketingURL: `${new URL(canonicalUrl).origin}${router.asPath}`,
                            },
                        ]}
                    >
                        <TopicWrapper {...props} />
                    </SubscriptionPlansProvider>
                </SubscriptionCTAExperimentProvider>
            </ExperimentationProvider>
        </>
    );
}

TopicPageFeature.getLayout = getLayout;
