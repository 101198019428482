import {action, observable} from 'mobx';

import {DiscoveryAPI, DiscoveryUnit, DiscoveryUnitItem} from '@udemy/discovery-api';
import {UDData} from '@udemy/ud-data';

import {DiscoveryItemImpressionEvent} from 'udemy-django-static/js/browse/events';
import {attachFrontendTrackingIds} from 'udemy-django-static/js/browse/tracking';

/**
 * Available options used to control discovery unit fetching
 */
export interface FetchUnitOptions {
    /**
     * Optional key used to prefetch data
     */
    prefetchKey?: string;
    /**
     * The index of the page being fetched, starting at 1
     */
    p?: number;
    /**
     * Primary ID of the object being displayed on this page (e.g. topic ID)
     */
    pageObjectId?: number;
    /**
     * Price-based unit aggregation to fetch (e.g. 'price-free')
     */
    price?: string;
}

/**
 * Properties used to populate the discovery list units in the store
 */
interface DiscoveryListUnitStoreProps {
    /**
     * Type of page that this store is being used on. See the PAGE_TYPE_* values in browse/lib/constants
     */
    pageType: string;
    /**
     * Primary ID of the object being displayed on this page (e.g. topic ID)
     */
    pageObjectId: number;

    globalOverrides?: UDData;
}

export class DiscoveryListUnitStore {
    private pageIndex: number;
    private pageType: string;
    private pageObjectId: number;
    backendSource = DiscoveryItemImpressionEvent.backendSourceOptions.DISCOVERY_ALL_COURSES;
    @observable.ref unit: DiscoveryUnit | null = null;
    @observable.ref items: DiscoveryUnitItem[] = [];
    @observable loading = false;
    @observable.ref error: Error | null = null;
    @observable hasMore = true;
    discoveryAPI: DiscoveryAPI;

    constructor({pageType, pageObjectId, globalOverrides}: DiscoveryListUnitStoreProps) {
        this.pageIndex = 1;
        this.pageType = pageType;
        this.pageObjectId = Number(pageObjectId);
        this.discoveryAPI = new DiscoveryAPI({}, globalOverrides);
    }

    get prefetchKey() {
        return this.pageType === 'topic' ? this.pageType : `${this.pageType}_all_courses`;
    }

    @action
    async fetchUnit(options: FetchUnitOptions) {
        options.prefetchKey = this.prefetchKey;
        options.p = this.pageIndex;
        if (!this.hasMore || this.loading) {
            return;
        }
        try {
            this.loading = true;
            const unit = await this.discoveryAPI.loadCourses(this.pageType, {
                pageObjectId: this.pageObjectId,
                ...options,
            });
            this.receiveUnit(unit as DiscoveryUnit);
        } catch (e) {
            this.receiveUnitError(e as Error);
        }
    }

    @action
    receiveUnit(unit: DiscoveryUnit) {
        this.hasMore = !!unit.remaining_item_count;
        if (0 < unit.remaining_item_count) {
            this.pageIndex += 1;
        }
        this.loading = false;
        attachFrontendTrackingIds(unit.items);
        this.items = this.items.concat(unit.items);
    }

    @action
    receiveUnitError(error: Error) {
        this.loading = false;
        this.error = error;
    }
}
