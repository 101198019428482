import {
    PAGE_TYPE_ORG_TOPIC,
    PAGE_TYPE_SUBS_TOPIC,
    PAGE_TYPE_TOPIC,
} from 'udemy-django-static/js/browse/lib/constants';

export type TopicPageType =
    | typeof PAGE_TYPE_TOPIC
    | typeof PAGE_TYPE_SUBS_TOPIC
    | typeof PAGE_TYPE_ORG_TOPIC;

export const isSubsTopicPage = (pageType: TopicPageType): boolean =>
    pageType === PAGE_TYPE_SUBS_TOPIC;
export const isOrgTopicPage = (pageType: TopicPageType): boolean =>
    pageType === PAGE_TYPE_ORG_TOPIC;
