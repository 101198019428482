import {computed} from 'mobx';
import React from 'react';

import {DiscoveryUnit} from '@udemy/discovery-api';
import {withMatchMedia} from '@udemy/hooks';
import {withI18n, WithI18nProps} from '@udemy/i18n';
import {MarketplaceOnly} from '@udemy/react-brand-components';
import {AlternateHeadline} from '@udemy/react-discovery-units';
import {QuestionsAndAnswers} from '@udemy/react-frequently-asked-questions';
import {withUDData, WithUDDataProps} from '@udemy/ud-data';

import {ComparisonModule} from 'src/features/category/components/browse/notices/comparison-module.react-component';
import {
    AGGREGATION_TYPE_PRICE,
    PAGE_TYPE_FREE_TOPIC,
} from 'udemy-django-static/js/browse/lib/constants';

import {DiscoveryListUnit} from './components/discovery-list-unit/discovery-list-unit.react-component';
import {RelatedPages} from './components/related-pages.react-component';
import {
    FreeTopicDiscoveryUnitsContainerWrapper,
    TopicDiscoveryUnitsContainerWrapperLoader,
} from './components/topic-discovery-units-wrapper.react-component';
import {TopicEnrollmentStats} from './components/topic-enrollment-stats.react-component';
import {PRESET_FREE} from './constants';
import {TopicPageExperimentVariants} from './topic.react-component';
import {TopicContext} from './types/topic';

import './topic.module.less';

export interface FreeTopicProps
    extends TopicContext,
        WithI18nProps,
        WithUDDataProps,
        TopicPageExperimentVariants {
    isMobile?: boolean | null;
}

@withMatchMedia({isMobile: 'mobile-max'})
export class InternalFreeTopic extends React.Component<FreeTopicProps> {
    static defaultProps = {
        presetValue: PRESET_FREE,
        relatedPages: [],
        questionsAndAnswers: [],
        isMobile: false,
    };

    @computed
    get arePageDataLoading() {
        return this.props.udData.isGlobalMeContextLoading;
    }

    get presetFilters() {
        return this.props.presetValue === PRESET_FREE
            ? {[AGGREGATION_TYPE_PRICE]: 'price-free'}
            : {};
    }

    get topic() {
        return {
            ...this.props.topic,
            /*
             * CLP returns the topic_channel_url from backend, therefore, use the same variable for URL.
             * topic_channel_url is using by comparison module and value props unit cta button.
             */
            topic_channel_url: this.props.topic.url,
        };
    }

    filterDiscoveryUnit(unit: Partial<DiscoveryUnit>) {
        /**
         * TODO: remove 'related_categories_and_subcategories' condition after the related categories and subcategories are removed from the DiscoveryAPI
         */
        return !['related_categories_and_subcategories', 'discovery_value_props'].includes(
            unit.type ?? '',
        );
    }

    render() {
        const isMobile = !!this.props.isMobile;
        const {gettext, interpolate} = this.props;

        return (
            <div className="ud-container ud-page-wrapper">
                <AlternateHeadline
                    title={this.props.topicTitle}
                    className="component-margin"
                    titleTag="h1"
                    titleClass="ud-heading-serif-xxl"
                    titleStyle="topic-page-title"
                />
                {!this.arePageDataLoading ? (
                    <FreeTopicDiscoveryUnitsContainerWrapper
                        {...this.props}
                        pageType={PAGE_TYPE_FREE_TOPIC}
                        discoveryUnitFilter={this.filterDiscoveryUnit}
                    >
                        {/* The below component is injected at the 0th index of the parent component */}
                        {this.props.enrollmentStat && (
                            <TopicEnrollmentStats
                                enrollmentStat={this.props.enrollmentStat}
                                data-item-index={0}
                            />
                        )}
                        <DiscoveryListUnit
                            isMobile={isMobile}
                            pageType={PAGE_TYPE_FREE_TOPIC}
                            pageObjectId={parseInt(this.props.topic.id, 10)}
                            presetFilters={this.presetFilters}
                            data-item-index={0}
                            className="component-margin"
                        />
                        <div className="component-margin" data-item-index={0}>
                            <RelatedPages
                                alternateHeadline={{
                                    title: interpolate(
                                        gettext('Free %(topicName)s lessons'),
                                        {topicName: this.props.topic.name},
                                        true,
                                    ),
                                    secondaryText: gettext('Bite-sized learning in minutes'),
                                }}
                                presetValue={this.props.presetValue}
                                relatedPages={this.props.relatedPages}
                                topic={this.props.topic}
                                showViewAllLink={false}
                                isMobile={isMobile}
                            />
                        </div>
                        <ComparisonModule
                            className="component-margin"
                            data-item-index={1}
                            topicData={this.topic}
                        />
                    </FreeTopicDiscoveryUnitsContainerWrapper>
                ) : (
                    <TopicDiscoveryUnitsContainerWrapperLoader isMobile={isMobile} />
                )}
                <MarketplaceOnly>
                    {this.props.questionsAndAnswers.length > 0 && (
                        <div className="component-margin">
                            <QuestionsAndAnswers
                                questionsAndAnswers={this.props.questionsAndAnswers}
                                defaultExpanded={true}
                            />
                        </div>
                    )}
                </MarketplaceOnly>
            </div>
        );
    }
}

export const FreeTopic = withI18n(withUDData(InternalFreeTopic));
