import classNames from 'classnames';
import {observer} from 'mobx-react';
import React, {Component} from 'react';

import {AsyncPriceCourseCard, BrowseCourse} from '@udemy/browse-course';
import {TrackingContextProvider} from '@udemy/event-tracking';
import {FunnelLogContextProvider, FunnelLogContextProviderProps} from '@udemy/funnel-tracking';
import {
    CourseDetailsQuickViewBoxLazy,
    CourseDetailsQuickViewBoxLazyProps,
    CourseUnitSkeleton,
} from '@udemy/react-discovery-units';
import {Carousel} from '@udemy/react-structure-components';

import CourseCardSkeleton from 'udemy-django-static/js/base-components/course-card/course-card-skeleton.react-component';
import {discoveryTracker} from 'udemy-django-static/js/browse/tracking';

import {DiscoveryListUnitStore, FetchUnitOptions} from './discovery-list-unit.mobx-store';
import styles from './discovery-list-unit.module.less';

interface DiscoveryListUnitProps {
    pageType: string;
    pageObjectId: number;
    presetFilters: FetchUnitOptions;
    className?: string;
    isMobile: boolean;
}

@observer
export class DiscoveryListUnit extends Component<DiscoveryListUnitProps> {
    constructor(props: DiscoveryListUnitProps) {
        super(props);
        const {pageObjectId, pageType} = this.props;
        this.store = new DiscoveryListUnitStore({pageType, pageObjectId});
    }

    componentDidMount() {
        this.fetchData();
    }

    store: DiscoveryListUnitStore;

    fetchData = () => {
        return this.store.fetchUnit({...this.props.presetFilters});
    };

    render() {
        const {isMobile} = this.props;

        if (!this.store.items.length && this.store.loading) {
            return <CourseUnitSkeleton className={this.props.className} layout="singlerow" />;
        }
        const courseCards = this.store.items.map((course, i) => (
            <TrackingContextProvider
                key={course.id}
                trackingContext={{
                    trackImpressionFunc: discoveryTracker.trackDiscoveryImpression,
                    index: i,
                    backendSource: this.store.backendSource,
                }}
            >
                <CourseDetailsQuickViewBoxLazy
                    course={course as CourseDetailsQuickViewBoxLazyProps['course']}
                    courseCard={<AsyncPriceCourseCard course={course as BrowseCourse} />}
                />
            </TrackingContextProvider>
        ));
        if (this.store.hasMore && isMobile) {
            courseCards.push(<CourseCardSkeleton size="medium" key={courseCards.length + 1} />);
        }
        return (
            <FunnelLogContextProvider
                pageType={this.props.pageType as FunnelLogContextProviderProps['pageType']}
            >
                <div className={this.props.className}>
                    <h2
                        className={classNames('ud-heading-xl', styles.heading)}
                        data-purpose="heading"
                    >
                        {gettext('Free learning on Udemy')}
                    </h2>

                    <Carousel
                        fullViewport={isMobile}
                        showPager={!isMobile}
                        allowScroll={isMobile}
                        onLoadMore={this.fetchData}
                        pagerButtonClassName={styles['pager-button']}
                        className={styles.grid}
                    >
                        {courseCards}
                    </Carousel>
                </div>
            </FunnelLogContextProvider>
        );
    }
}
