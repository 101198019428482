import {ClientEvent} from '@udemy/event-tracking';

/** This event is fired when a user visits a topic page within the Monolith. */
export class TopicPageViewEvent extends ClientEvent {
    constructor({topicId}: {topicId: number}) {
        super('TopicPageViewEvent');
        this.topicId = topicId;
    }

    topicId: number;
}
