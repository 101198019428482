import {useMatchMedia} from '@udemy/hooks';
import classNames from 'classnames';
import React from 'react';

import {Carousel} from '@udemy/react-structure-components';

import {CertificationUnitCard} from './certification-unit-card.react-component';
import {CertificationModel} from '../certification.mobx-model';
import styles from './certification-carousel.module.less';

export interface CertificationCarouselProps {
    title: string;
    certifications: CertificationModel[];
    className?: string;
}

export const CertificationCarousel = ({
    title,
    certifications,
    className,
}: CertificationCarouselProps) => {
    const isMobileMax = useMatchMedia('mobile-max');
    const hasCoarsePointer = useMatchMedia('(any-pointer: coarse)');
    const hasFinePointer = useMatchMedia('(any-pointer: fine)');

    return (
        <section className={classNames(className, styles['certification-unit-container'])}>
            <h2
                className={classNames('ud-heading-lg', styles['certification-unit-container'])}
                data-purpose="certification-unit-title"
            >
                {title}
            </h2>
            <Carousel
                data-purpose="carousel"
                fullViewport={!!isMobileMax}
                showPager={!!hasFinePointer}
                allowScroll={!!hasCoarsePointer}
                className={styles['certification-carousel']}
            >
                {certifications?.map((cert) => (
                    <CertificationUnitCard key={cert.id} certification={cert} />
                ))}
            </Carousel>
        </section>
    );
};
