import {withI18n} from '@udemy/i18n';
import {noop} from '@udemy/shared-utils';
import classNames from 'classnames';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import styles from './related-categories-unit.module.less';
import {Tracker} from '@udemy/event-tracking';
import {
    BrowseNavigationItemSelected,
    BrowseNavigationItemStyle,
} from '@udemy/browse-event-tracking';

class RelatedCategoriesUnit extends Component {
    static propTypes = {
        className: PropTypes.string,
        onLoad: PropTypes.func,
        trackImpressionFunc: PropTypes.func.isRequired,
        relatedCategoriesAndSubcategories: PropTypes.arrayOf({
            id: PropTypes.number,
            title: PropTypes.string,
            url: PropTypes.string,
        }),
        compact: PropTypes.bool,
        gettext: PropTypes.func.isRequired,
        interpolate: PropTypes.func.isRequired,
        topicTitle: PropTypes.string,
    };
    
    static defaultProps = {
        className: undefined,
        onLoad: noop,
        compact: false,
        topicTitle: '',
    };

    componentDidMount() {
        this.props.onLoad();
    }

    render() {
        const {className, relatedCategoriesAndSubcategories, compact, gettext, interpolate, topicTitle} = this.props;

        const mostRelevantItems = relatedCategoriesAndSubcategories.slice(0, 2);

        return (
            <h2 className={classNames(className, `ud-heading-${compact ? 'sm' : 'md'}`)}>
                {interpolate(
                    gettext('%(topicTitle)s relates to '),
                    {topicTitle},
                    true,
                )}
                {mostRelevantItems.map((item) => {
                    const trackClick = () => {
                        Tracker.publishEvent(
                            new BrowseNavigationItemSelected({
                                id: String(item.id),
                                type: 'category',
                                title: item.title,
                                url: item.url,
                                uiRegion: 'related_categories_unit',
                                style: BrowseNavigationItemStyle.LINK,
                            }),
                        );
                    };
                    return (
                        <a
                            href={item.url}
                            key={item.id}
                            className={styles['item-link']}
                            onClick={trackClick}
                        >
                            {item.title}
                        </a>
                    );
                })}
            </h2>
        );
    }
}

export default withI18n(RelatedCategoriesUnit);
