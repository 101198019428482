import {observable, runInAction} from 'mobx';
import {observer, Provider} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {withUDData} from '@udemy/ud-data';

const createUFBContextMenu = () =>
    import(
        /* webpackChunkName: "create-ufb-context-menu" */ 'udemy-django-static/js/organization-common/resource-context-menu/create-ufb-context-menu'
    );
@observer
class InternalResourceContextMenuProvider extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        resourceContextMenuProps: PropTypes.object,
        udData: PropTypes.object.isRequired,
    };

    static defaultProps = {
        resourceContextMenuProps: {},
    };

    async componentDidMount() {
        const {udData} = this.props;
        // We should enable when we have notion of the organization.
        if (udData.Config.brand.has_organization) {
            const createUFBContextMenuModule = await createUFBContextMenu();
            runInAction(() => {
                this.resourceContextMenu = createUFBContextMenuModule.default(udData);
            });
        }
        runInAction(() => {
            this.isLoading = false;
        });
    }

    @observable.ref resourceContextMenu = undefined;
    @observable.ref isLoading = true;

    render() {
        const {udData} = this.props;
        if (udData.Config.brand.has_organization && this.isLoading) {
            return null;
        }
        const {children, resourceContextMenuProps} = this.props;
        return (
            <Provider
                resourceContextMenu={this.resourceContextMenu}
                resourceContextMenuProps={resourceContextMenuProps}
            >
                {children}
            </Provider>
        );
    }
}

const ResourceContextMenuProvider = withUDData(InternalResourceContextMenuProvider);

export default ResourceContextMenuProvider;
