import {observer} from 'mobx-react';
import React from 'react';

import {BrowseCourseBadgesContext} from '@udemy/browse-course';
import {CertificationBundle} from '@udemy/react-certification-preparation';
import {useFeatures} from '@udemy/react-experimentation-provider';
import {useStores} from '@udemy/store-provider';
import {useConsumerSubscriptionPlan, ConsumerSubscriptionPlan} from '@udemy/subscription-graphql';

import {
    ADD_SUBHEADINGS_TO_CAT_SUBCAT_TOPIC_RECOMMENDATIONS,
    BROWSE_AGGREGATOR_PAGE_ENABLED,
    DELAY_COURSE_CARD_QUICK_VIEW_BOX,
    HIDE_WISHLIST_BUTTON_ON_COURSE_CARD_QUICK_VIEW_BOX,
    MARKETPLACE_SUBSCRIPTION_OFFER,
    SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE,
    UPDATE_PERSONAL_PLAN_TOPIC_PAGE,
    USE_STANDARDIZE_MOBILE_BROWSE_CAROUSEL,
    CERTIFICATION_BUNDLE_ON_TOPIC_CLP,
    CERTIFICATION_BUNDLE_EXPANSION,
    USE_VERTICAL_MOBILE_BROWSE_LAYOUT,
    GENERIC_BUNDLES_ON_TOPICS,
    COURSE_GUIDANCE,
    QUICK_VIEW_BOX_FEATURE,
} from 'src/lib/experimentation-constants';
import {getBrowseCourseBadgesContextOptionsByExperiment} from 'src/lib/experimentation-helper';
import {RootStore} from 'src/stores/root-store';

import {TOPIC_PRESET_VALUES} from './constants';
import {FreeTopic} from './free-topic.react-component';
import {Topic, TopicPageExperimentVariants, TopicProps} from './topic.react-component';

export type TopicWrapperProps = Omit<TopicProps, 'udData'>;

export const TopicPageExperimentationFeatures = [
    UPDATE_PERSONAL_PLAN_TOPIC_PAGE,
    MARKETPLACE_SUBSCRIPTION_OFFER,
    USE_STANDARDIZE_MOBILE_BROWSE_CAROUSEL,
    ADD_SUBHEADINGS_TO_CAT_SUBCAT_TOPIC_RECOMMENDATIONS,
    SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE,
    DELAY_COURSE_CARD_QUICK_VIEW_BOX,
    HIDE_WISHLIST_BUTTON_ON_COURSE_CARD_QUICK_VIEW_BOX,
    BROWSE_AGGREGATOR_PAGE_ENABLED,
    CERTIFICATION_BUNDLE_ON_TOPIC_CLP,
    CERTIFICATION_BUNDLE_EXPANSION,
    USE_VERTICAL_MOBILE_BROWSE_LAYOUT,
    GENERIC_BUNDLES_ON_TOPICS,
    COURSE_GUIDANCE,
    QUICK_VIEW_BOX_FEATURE,
];

export const TopicWrapper: React.FC<TopicWrapperProps> = observer((props: TopicWrapperProps) => {
    const consumerSubscriptionPlan = useConsumerSubscriptionPlan();
    const {isLoading, featureVariantAssignments: features} = useFeatures(
        TopicPageExperimentationFeatures,
    );

    const experimentProps: TopicPageExperimentVariants = {
        isExperimentConfigsLoading: isLoading,
        showUpdatedPPTopicPage:
            features.get(UPDATE_PERSONAL_PLAN_TOPIC_PAGE)?.configuration?.showUpdatedPPTopicPage ===
            'on'
                ? true
                : false,
        showPersonalPlanBadge:
            features.get(MARKETPLACE_SUBSCRIPTION_OFFER)?.configuration?.show_personal_plan_badge ??
            false,
        // Experiment is archived. We will be removing this soon.
        showCodingExercisesBadge: false,
        useStandardizeMobileBrowseCarousel:
            features.get(USE_STANDARDIZE_MOBILE_BROWSE_CAROUSEL)?.configuration
                ?.useStandardizeBrowseCarousel ?? false,
        showSubheadingsInCatSubcatTopicRecommendations:
            features.get(ADD_SUBHEADINGS_TO_CAT_SUBCAT_TOPIC_RECOMMENDATIONS)?.configuration ?? {},
        browseAggregatorPageEnabled:
            features.get(BROWSE_AGGREGATOR_PAGE_ENABLED)?.configuration?.pageEnabled ?? false,
        useVerticalMobileBrowseLayout:
            features.get(USE_VERTICAL_MOBILE_BROWSE_LAYOUT)?.configuration
                ?.useVerticalBrowseLayout ?? false,
        showCourseGuidance:
            features.get(COURSE_GUIDANCE)?.configuration?.show_course_guidance_feature ?? false,
        fullQuickViewBoxFeature:
            features.get(QUICK_VIEW_BOX_FEATURE)?.configuration?.feature ?? false,
    };

    const subscriptionCatalogDifferentiatorFeature =
        features.get(SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE)?.configuration ?? {};

    const [rootStore] = useStores([RootStore]);

    const isConsumerSubsSubscriber =
        (consumerSubscriptionPlan &&
            !consumerSubscriptionPlan.isLoading &&
            consumerSubscriptionPlan?.isSubscriber) ??
        false;

    const hydratePersonalPlanInfo = (bundle?: CertificationBundle) => {
        if (!consumerSubscriptionPlan?.isLoading && !!bundle) {
            const certificationBundleCourses = bundle.contents.map((course) => {
                return {
                    ...course,
                    is_in_personal_plan_collection: (
                        consumerSubscriptionPlan as ConsumerSubscriptionPlan
                    )?.contentCollectionIds?.some((subsCollectionId: string) =>
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (course as unknown as any).content_collections?.some(
                            (courseCollection: {id: number}) =>
                                // String conversion to make this robust to ids that are both strings and numbers
                                courseCollection.id.toString() === subsCollectionId.toString(),
                        ),
                    ),
                };
            });
            bundle.contents = certificationBundleCourses;
            return bundle;
        } else {
            return bundle;
        }
    };

    const topicProps = {
        ...props,
        ...experimentProps,
        presetValue: props.presetValue || TOPIC_PRESET_VALUES.ALL,
        relatedPages: props.relatedPages ?? [],
        isConsumerSubsSubscriber,
        isSubsLoading: consumerSubscriptionPlan?.isLoading ?? true,
        enableAssessmentsInPersonalPlan: rootStore.headerData.user?.enableAssessmentsInPersonalPlan,
        enableLabsInPersonalPlan: rootStore.headerData.user?.enableLabsInPersonalPlan,
        certification: props.certification,
        certificationBundle: hydratePersonalPlanInfo(props.certificationBundle),
    };

    return (
        <>
            <BrowseCourseBadgesContext.Provider
                value={{
                    browseBadgeHandlerOptions: getBrowseCourseBadgesContextOptionsByExperiment(
                        subscriptionCatalogDifferentiatorFeature,
                    ),
                }}
            >
                {props.presetValue === 'free' ? (
                    <FreeTopic {...topicProps} />
                ) : (
                    <Topic {...topicProps} />
                )}
            </BrowseCourseBadgesContext.Provider>
        </>
    );
});
