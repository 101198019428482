import React, {Component} from 'react';

import {TrackingContextProvider} from '@udemy/event-tracking';
import {useI18n, withI18n, WithI18nProps} from '@udemy/i18n';
import {Button} from '@udemy/react-core-components';
import {AlternateHeadline} from '@udemy/react-discovery-units';
import {Carousel} from '@udemy/react-structure-components';

import {PRESET_ALL, PRESET_FREE} from 'src/features/topic/constants';
import {Topic} from 'src/generated/graphql';
import {DiscoveryItemImpressionEvent} from 'udemy-django-static/js/browse/events';
import {discoveryTracker} from 'udemy-django-static/js/browse/tracking';
import {
    VideoCard,
    VideoData,
} from 'udemy-django-static/js/topic/components/video-card.react-component';

import {RelatedPage} from '../types/topic';
import styles from './related-pages.module.less';

const backendSource = DiscoveryItemImpressionEvent.backendSourceOptions.RELATED_LECTURES;

interface TopicPageLinkProps {
    presetValue: string;
    topicTitle: string;
    url: string;
}

export function TopicPageLink({presetValue, topicTitle, url}: TopicPageLinkProps) {
    const {gettext, interpolate} = useI18n();
    return (
        <Button componentClass="a" href={url} udStyle="ghost">
            {interpolate(
                gettext('View %(topicType)s %(topicTitle)s courses'),
                {topicType: presetValue, topicTitle},
                true,
            )}
        </Button>
    );
}

interface AlternateHeadline {
    title: string;
    secondaryText?: string;
}

interface RelatedPagesProps extends WithI18nProps {
    alternateHeadline?: AlternateHeadline;
    className?: string;
    topic: Pick<Topic, 'name'>;
    presetValue: string;
    relatedPages: RelatedPage[];
    showViewAllLink: boolean;
    isMobile?: boolean;
}

export class InternalRelatedPages extends Component<RelatedPagesProps> {
    static defaultProps = {
        showViewAllLink: true,
        experiment: {variant: 'control'},
        isMobile: false,
    };

    getPagesAndUrl() {
        const {relatedPages, presetValue} = this.props;
        const pages: RelatedPage[] = [];
        let url: string | null = null;
        for (const page of relatedPages) {
            if (page.type === 'course_label') {
                const freeTopicUrl =
                    page.kwargs.preset_filter_val === PRESET_FREE && presetValue !== PRESET_FREE;
                const paidTopicUrl =
                    page.kwargs.preset_filter_val === PRESET_ALL && presetValue !== PRESET_ALL;
                if (freeTopicUrl || paidTopicUrl) {
                    url = page.url;
                }
            } else if (page.type !== 'course_label') {
                pages.push(page);
            }
        }

        return {pages, url};
    }

    getHeader() {
        const {alternateHeadline} = this.props;
        if (alternateHeadline) {
            return <AlternateHeadline titleTag="h2" {...alternateHeadline} />;
        }
        return <h2 className={styles['unit-title']}>{this.props.gettext('Related pages')}</h2>;
    }

    render() {
        const {isMobile} = this.props;
        const {pages, url} = this.getPagesAndUrl();
        if (!pages.length) {
            return null;
        }
        const presetValue = this.props.presetValue === PRESET_FREE ? 'all' : 'free';
        return (
            <TrackingContextProvider
                trackingContext={{
                    trackImpressionFunc: discoveryTracker.trackDiscoveryImpression,
                    backendSource,
                }}
            >
                <div className={this.props.className} data-purpose="related-pages">
                    {this.getHeader()}
                    <Carousel
                        fullViewport={isMobile}
                        showPager={!isMobile}
                        allowScroll={!!isMobile}
                    >
                        {pages.map((relatedPage, i) => {
                            // In case `tracking_id` is not present on cached data,
                            // fallback to a unique tracking ID string to ensure
                            // proper collection of impression event
                            const trackingId = relatedPage.tracking_id
                                ? `${relatedPage.tracking_id}`
                                : `useless-${relatedPage.lecture_id}-${i}`;
                            const trackingData = {
                                id: relatedPage.lecture_id,
                                type: relatedPage.type,
                                tracking_id: trackingId,
                            };
                            return (
                                <VideoCard
                                    key={i}
                                    data={relatedPage as VideoData}
                                    trackingData={trackingData}
                                    index={i}
                                />
                            );
                        })}
                    </Carousel>
                    {this.props.showViewAllLink && url && (
                        <TopicPageLink
                            presetValue={presetValue}
                            topicTitle={this.props.topic.name}
                            url={url}
                        />
                    )}
                </div>
            </TrackingContextProvider>
        );
    }
}

export const RelatedPages = withI18n(InternalRelatedPages);
