import classNames from 'classnames';
import React from 'react';

import {useI18n} from '@udemy/i18n';
import CloseIcon from '@udemy/icons/dist/close.ud-icon';
import TickIcon from '@udemy/icons/dist/tick.ud-icon';
import {MarketplaceOnly} from '@udemy/react-brand-components';
import {BlockList, Button} from '@udemy/react-core-components';

import {Topic} from 'src/generated/graphql';

import {ComparisonModuleStore} from './comparison-module.mobx-store';
import styles from './comparison-module.module.less';
import {RefundNotice} from './refund-notice.react-component';

export interface ComparisonModuleProps {
    topicData?: Topic & {topic_channel_url: string};
    className?: string;
}

export const ComparisonModule = ({topicData, className = ''}: ComparisonModuleProps) => {
    const {gettext, interpolate} = useI18n();
    if (!topicData || !topicData.name) {
        return null;
    }
    const store = new ComparisonModuleStore(topicData, gettext);
    const comparisonLists = store.unit.map((list, index) => (
        <div key={index} className={styles.list} data-purpose="comparison-module-list">
            <h4 className={classNames('ud-heading-lg', styles['module-title'])}>{list.title}</h4>
            <BlockList size="large" padding="tight">
                {list.content.map((item, i) => (
                    <BlockList.Item
                        key={i}
                        icon={
                            item.is_active ? (
                                <TickIcon label={false} color="positive" />
                            ) : (
                                <CloseIcon label={false} color="negative" />
                            )
                        }
                        className={classNames({[styles['is-active']]: item.is_active})}
                    >
                        {item.text}
                    </BlockList.Item>
                ))}
            </BlockList>
        </div>
    ));

    return (
        <MarketplaceOnly>
            <div className={classNames('ud-container', className, styles.wrapper)}>
                <div className={styles.header}>
                    <h2 className={classNames('ud-heading-lg', styles['unit-title'])}>
                        {gettext('Try free courses or enroll in paid courses')}
                    </h2>
                    <RefundNotice fullWidth={false} className={styles['refund-notice']} />
                    <Button
                        componentClass="a"
                        className={styles['view-more-button']}
                        href={topicData.topic_channel_url}
                        udStyle="secondary"
                    >
                        {interpolate(
                            gettext('View paid %(topicTitle)s courses'),
                            {topicTitle: topicData.name},
                            true,
                        )}
                    </Button>
                </div>
                <div className={styles['comparison-lists']}>{comparisonLists}</div>
            </div>
        </MarketplaceOnly>
    );
};
