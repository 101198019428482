import {observer} from 'mobx-react';
import React from 'react';

import {CertificationCarousel} from './certification-carousel.react-component';
import {CertificationUnitStore} from './certification-unit.mobx-store';
import {withI18n, WithI18nProps} from '@udemy/i18n';

export interface CertificationUnitProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    topicId: string;
    certificationUnitStore: CertificationUnitStore;
    gettext: WithI18nProps['gettext'];
    interpolate: WithI18nProps['interpolate'];
}

@observer
export class InternalCertificationUnit extends React.Component<CertificationUnitProps> {
    static defaultProps = {
        className: '',
        certificationUnitStore: new CertificationUnitStore(),
    };

    async componentDidMount() {
        const {topicId, certificationUnitStore} = this.props;
        await certificationUnitStore.getCertificationsByTopic(topicId);
    }

    render() {
        const {interpolate, gettext} = this.props;
        const {certificationsList} = this.props.certificationUnitStore;
        if (!certificationsList || certificationsList.length == 0) {
            return null;
        }

        const issuerName = certificationsList[0].issuer.name;
        return (
            <div data-purpose="certification-unit" className={this.props.className}>
                <CertificationCarousel
                    certifications={certificationsList}
                    title={interpolate(
                        gettext('Choose your certification from %(issuer)s'),
                        {issuer: issuerName},
                        true,
                    )}
                />
            </div>
        );
    }
}

export const CertificationUnit = withI18n(InternalCertificationUnit);
