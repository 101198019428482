import {action, observable} from 'mobx';

import {useBadgeClassesByTopicQuery} from 'udemy-django-static/js/gql-codegen/api-platform-graphql';

import {CertificationModel} from '../certification.mobx-model';
import {udSentry} from '@udemy/sentry';

export class CertificationUnitStore {
    @observable certificationsList?: CertificationModel[];

    @action
    async getCertificationsByTopic(topicId: string) {
        try {
            const response = await useBadgeClassesByTopicQuery.fetcher({topicId})();
            const badgeItem = response.badgeClassesByTopic;
            this.setCertificationListFromResponse(badgeItem);
        } catch (e) {
            udSentry.captureException(e);
        }
    }

    @action
    setCertificationListFromResponse(badgeClasses: any) {
        // TODO: use the correct type
        this.certificationsList = badgeClasses.map((badgeClass: any) => {
            return new CertificationModel(badgeClass);
        });
    }
}
