import {Skeleton} from '@udemy/react-reveal-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './discovery-units-skeletons.module.less';

export const SkeletonTitle = ({className = ''}) => {
    return (
        <div
            className={classNames(styles['skeleton-title'], className)}
            data-purpose="discovery-units-skeleton-title"
        />
    );
};

SkeletonTitle.propTypes = {
    className: PropTypes.string,
};

export const SkeletonCourseCarousel = ({courseCount}) => {
    const courseInfoClassName = styles['course-info'];
    return (
        <Skeleton>
            <div
                className={styles['skeleton-unit skeleton-spacing']}
                data-purpose="discovery-units-skeleton-course-carousel"
            >
                <SkeletonTitle />
                <div className={styles.carousel}>
                    {[...Array(courseCount).keys()].map((key) => (
                        <div key={key} className={styles['course-container']}>
                            <div className={styles['course-image-lg']} />
                            <div className={styles['info-container']}>
                                <div className={courseInfoClassName} />
                                <div className={courseInfoClassName} />
                                <div className={courseInfoClassName} />
                                <div className={courseInfoClassName} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Skeleton>
    );
};

SkeletonCourseCarousel.propTypes = {
    courseCount: PropTypes.number.isRequired,
};

export const SkeletonCourseList = ({courseCount}) => {
    const courseInfoClassName = styles['course-info'];
    return (
        <div
            className={classNames(styles['skeleton-unit'], styles['skeleton-spacing'])}
            data-purpose="discovery-units-skeleton-course-list"
        >
            <div className={styles.title} />
            {[...Array(courseCount).keys()].map((key) => (
                <div key={key} className={styles['skeleton-card']}>
                    <div className={styles['course-image']} />
                    <div className={styles['info-container']}>
                        <div className={courseInfoClassName} />
                        <div className={courseInfoClassName} />
                        <div className={courseInfoClassName} />
                        <div className={courseInfoClassName} />
                    </div>
                </div>
            ))}
        </div>
    );
};

SkeletonCourseList.propTypes = {
    courseCount: PropTypes.number.isRequired,
};
