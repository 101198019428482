export const SOURCE_PAGE_TYPE = 'topic';
export const PRESET_ALL = 'all';
export const PRESET_FREE = 'free';

export const TOPIC_PRESET_VALUES = {
    ALL: 'all',
    FREE: 'free',
    BEGINNER: 'beginner',
    EXPERT: 'expert',
} as const;

export const CERT_BUNDLE_DISCOUNT_CODE = 'BUNDLE_COUPON';
