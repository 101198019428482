import {dehydrate, QueryClient} from '@tanstack/react-query';
import {GetStaticPropsContext} from 'next';

import {DiscoveryAPI, DiscoveryUnit} from '@udemy/discovery-api';
import {RENDER_MODES} from '@udemy/nextjs-core';
import {getBundleFromTopic} from '@udemy/react-certification-preparation';
import {udSentry} from '@udemy/sentry';

import {TopicPageFeature, TopicPageFeatureProps} from 'src/features/topic';
import {TOPIC_PRESET_VALUES} from 'src/features/topic/constants';
import {RedirectContext, TopicContext, TopicPresetValue} from 'src/features/topic/types/topic';
import {useBadgeClassesByTopicQuery} from 'src/generated/graphql';
import {getCompactTranslations} from 'src/lib-server/locale.server';
import {FOUR_HOURS} from 'src/lib/constants';
import {logger} from 'src/lib/logger';
import {AppContextData} from 'src/types/page-props';
import {CertificationModel} from 'udemy-django-static/js/open-badges/certification.mobx-model';

import {getStaticAppContext} from '../api/context.api';
import {TopicGqlError, TopicNotFoundError, TopicValidationError} from './lib/errors';
import {getCourseData} from './lib/get-course-data';
import {TopicRelatedDataItem, transformTopicRelatedDataResponse} from './lib/get-related-data';
import {getTopicData, shouldRedirect} from './lib/get-topic-data';

const checkPresetValue = (presetValue: TopicPresetValue) =>
    !presetValue || Object.values(TOPIC_PRESET_VALUES).includes(presetValue);

const getParsedTopicRequest = (topic: string[] = []) => {
    const [topicSlug, presetValue, ...rest] = topic;
    return {
        topic: topicSlug,
        presetValue: (presetValue ?? '') as TopicPresetValue,
        rest,
    };
};

const validateTopicQuery = (topicQuery: string[] | undefined) => {
    let errMsg = '';

    if (!topicQuery) {
        errMsg = 'Topic query param is missing.';
    }

    const {topic, presetValue, rest} = getParsedTopicRequest(topicQuery as string[]);

    if (!topic && !errMsg) {
        errMsg = 'Topic query param is empty.';
    }

    if (presetValue && !checkPresetValue(presetValue)) {
        errMsg = 'Preset value is not valid.';
    }

    if (rest.length > 0) {
        errMsg = 'Topic query param is not valid.';
    }

    if (errMsg) {
        logger.log({
            level: 'error',
            message: 'Invalid topic query param',
            topicQuery,
            presetValue,
            error: errMsg,
        });
        throw new TopicValidationError(errMsg);
    }

    return true;
};

export async function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}

export async function getStaticProps(context: GetStaticPropsContext) {
    const locale = context.locale as string;
    const params = context.params;
    // Common page data
    const translations = await getCompactTranslations(locale);
    const client = new QueryClient();

    // Topic page specific data
    // We will use topic and presetValue in the future for fetching the real topic data
    const {topic, presetValue} = getParsedTopicRequest(params?.topic as string[]);
    let staticData: AppContextData, topicData: TopicContext;
    try {
        validateTopicQuery(params?.topic as string[]);

        // Resolve appContext and fetch topic context
        [staticData, topicData] = await Promise.all([
            getStaticAppContext({locale}),
            getTopicData(topic, presetValue, locale),
        ]);
    } catch (e) {
        udSentry.captureException(e);

        if (e instanceof TopicValidationError || e instanceof TopicNotFoundError) {
            // Exception logging is handled in lower exceptions.
            return {
                notFound: true,
            };
        }

        // Some exceptions we don't want to log here because they are handled in the lower exception level.
        const shouldLogException = !(e instanceof TopicGqlError);

        if (shouldLogException) {
            logger.log({
                level: 'error',
                message: 'unknown exception on topic page',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error: (e as any)?.message,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                stack: (e as any)?.stack,
            });
        }

        throw e;
    }

    const appContext = {
        ...staticData,
    };

    if (shouldRedirect(topicData)) {
        const redirectContext = topicData.redirect_context as RedirectContext;
        const redirectionPath = redirectContext.destination;

        logger.log({
            level: 'info',
            locale,
            message: 'Topic view has been redirected',
            params,
            presetValue,
            topic,
            topicData,
        });

        /**
         * Normally, next.js returns 308 when request is permanently moved. But in udemy, we've different status
         * codes and for the topics we're using 301.
         *
         * But somehow, next.js caches this 308 responses and returns the same page for the next request.
         * TODO: GET THE REQUEST FROM THE API IT'S AVAILABLE, THIS IS JUST FOR TEST PURPOSES
         */
        const permanentContext = redirectContext.permanent
            ? {
                  statusCode: 301,
              }
            : {
                  permanent: false,
              };
        return {
            redirect: {
                destination: `${redirectionPath.replace(/\/$/, '')}/${presetValue}`,
                ...permanentContext,
            },
        };
    }

    let certification = undefined;
    let staticDiscoveryUnits = undefined;
    let certificationBundle = getBundleFromTopic(topic);
    let relatedCategoriesAndSubcategories: TopicRelatedDataItem[] = [];
    let genericTopicBundleCourses = undefined;

    try {
        const topicDiscoveryAPI = new DiscoveryAPI({}, appContext?.udData);
        const [
            certificationResult,
            discoveryUnitsResult,
            certificationBundleCoursesResult,
            relatedCategoriesAndSubcategoriesResult,
        ] = await Promise.allSettled([
            useBadgeClassesByTopicQuery.fetcher({
                topicId: topicData.topic.id,
            })(),
            topicDiscoveryAPI.loadUnits('topic_static', {
                pageObjectId: parseInt(topicData.topic.id, 10),
            }),
            getCourseData(certificationBundle?.contents?.map((c) => c.id)),
            topicDiscoveryAPI.loadUnits('topic-contextual-header', {
                pageObjectId: parseInt(topicData.topic.id, 10),
            }),
        ]);

        if (certificationResult.status === 'fulfilled') {
            const certificationResponse = certificationResult.value;
            const badgeItems = certificationResponse?.badgeClassesByTopic;
            certification = badgeItems?.find((badgeClass) => new CertificationModel(badgeClass));
        } else {
            // Swallow the exception, as we can continue to render the page even if we don't get a certification back.
            udSentry.captureException(certificationResult.reason);
        }

        if (discoveryUnitsResult.status === 'fulfilled') {
            // Swallow the exception, as we'll get other discovery units during CSR.
            staticDiscoveryUnits = discoveryUnitsResult.value.results;
        } else {
            udSentry.captureException(discoveryUnitsResult.reason);
        }

        if (certificationBundleCoursesResult.status === 'fulfilled') {
            if (certificationBundle) {
                certificationBundle.contents = certificationBundleCoursesResult.value;
            }
        } else {
            certificationBundle = undefined;
            udSentry.captureException(certificationBundleCoursesResult.reason);
        }

        if (relatedCategoriesAndSubcategoriesResult.status === 'fulfilled') {
            relatedCategoriesAndSubcategories = transformTopicRelatedDataResponse(
                relatedCategoriesAndSubcategoriesResult.value.results,
            );
        } else {
            // Swallow the exception, ss we can continue to render the page even if we don't get topic related Categories/Subcategories.
            udSentry.captureException(relatedCategoriesAndSubcategoriesResult.reason);
        }

        const skillsHubUnit = staticDiscoveryUnits?.find?.((unit: DiscoveryUnit) => {
            // Find a skills hub unit that has a course in it.
            return (
                unit.type === 'bestseller' &&
                unit.view_type === 'horizontal_tabbed' &&
                !!unit.items[0]?.id
            );
        });

        if (!certificationBundle && skillsHubUnit) {
            try {
                const bundledCourses = (
                    await topicDiscoveryAPI.loadUnits('clp-bundle', {
                        pageObjectId: skillsHubUnit.items[0].id,
                    })
                ).results[0].items.slice(0, 2);

                if (bundledCourses?.length === 2) {
                    genericTopicBundleCourses = bundledCourses;
                }
            } catch (e) {
                udSentry.captureException(e);
            }
        }
    } catch (e) {
        udSentry.captureException(e);
    }

    const staticProps = {
        props: {
            appContext,
            dehydratedState: dehydrate(client),
            locale,
            pageKey: 'discovery_topic',
            renderMode: RENDER_MODES.ISR, // supports render functions in _document.tsx and _app.tsx.
            translations,
            ...topicData,
            relatedCategoriesAndSubcategories,
            presetValue: presetValue,
        },
        revalidate: FOUR_HOURS,
    };

    // Avoid having an 'undefined' member in the static props object.
    if (certification) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (staticProps.props as any).certification = certification;
    }
    if (staticDiscoveryUnits) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (staticProps.props as any).staticDiscoveryUnits = staticDiscoveryUnits;
    }
    if (certificationBundle) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (staticProps.props as any).certificationBundle = certificationBundle;
    }
    if (genericTopicBundleCourses) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (staticProps.props as any).genericTopicBundleCourses = genericTopicBundleCourses;
    }

    return staticProps;
}

export default function TopicPage(props: TopicPageFeatureProps) {
    return <TopicPageFeature {...props} />;
}

TopicPage.getLayout = TopicPageFeature.getLayout;
