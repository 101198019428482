import classNames from 'classnames';
import {action, computed, observable} from 'mobx';
import {observer, Provider} from 'mobx-react';
import {WithRouterProps} from 'next/dist/client/with-router';
import {withRouter} from 'next/router';
import React from 'react';

import {BrowseCourse} from '@udemy/browse-course';
import {DiscoveryUnit} from '@udemy/discovery-api';
import {Tracker} from '@udemy/event-tracking';
import {withMatchMedia} from '@udemy/hooks';
import {withI18n, WithI18nProps} from '@udemy/i18n';
import {MarketplaceOnly} from '@udemy/react-brand-components';
import {ContextualHeader} from '@udemy/react-browse-contextual-header';
import {
    CertificationBundleUnit,
    CertificationBundle,
    isBundleInSet,
} from '@udemy/react-certification-preparation';
import {CourseGuidanceTakeShortAssessmentEntrypoint} from '@udemy/react-course-highlights';
import {
    AlternateHeadline,
    DiscoveryUnitsContainerStore,
    ValueProps,
    HorizontalBundleUnit,
} from '@udemy/react-discovery-units';
import {serverOrClient} from '@udemy/shared-utils';
import {
    BrandWithOrganization,
    CUSTOM_EVENT_NAME_PROVIDER_BRIDGE,
    withUDData,
    WithUDDataProps,
} from '@udemy/ud-data';

import {SkeletonCourseList} from 'udemy-django-static/js/browse/components/discovery-units-container/discovery-units-skeletons.react-component';
import {SkeletonTitle} from 'udemy-django-static/js/browse/components/discovery-units-container/discovery-units-skeletons.react-component';
import RelatedCategoriesUnit from 'udemy-django-static/js/browse/components/discovery-units/related-categories-unit/related-categories-unit.react-component';
import {
    AGGREGATION_TYPE_PRICE,
    PAGE_TYPE_ORG_TOPIC,
    PAGE_TYPE_SUBS_TOPIC,
    PAGE_TYPE_TOPIC,
} from 'udemy-django-static/js/browse/lib/constants';
import {CertificationModel} from 'udemy-django-static/js/open-badges/certification.mobx-model';
import {CertificationUnit} from 'udemy-django-static/js/open-badges/open-badge-unit/certification-unit.react-component';
import {CONTEXT_TYPES} from 'udemy-django-static/js/organization-common/resource-context-menu/constants';
import ResourceContextMenuProvider from 'udemy-django-static/js/organization-common/resource-context-menu/resource-context-menu-provider.react-component';

import {LearnMoreSection} from './components/learn-more-section.react-component';
import {TopicCertificationBanner} from './components/topic-certification-banner.react-component';
import {
    TopicDiscoveryListContainerWrapper,
    TopicDiscoveryUnitsContainerWrapper,
    TopicDiscoveryUnitsContainerWrapperLoader,
} from './components/topic-discovery-units-wrapper.react-component';
import {TopicEnrollmentStats} from './components/topic-enrollment-stats.react-component';
import {CERT_BUNDLE_DISCOUNT_CODE, PRESET_ALL, PRESET_FREE} from './constants';
import {TopicPageViewEvent} from './events';
import styles from './topic.module.less';
import {isOrgTopicPage, isSubsTopicPage, TopicPageType} from './types/page';
import {TopicContext} from './types/topic';
import {getPageObjectIdFromTopic} from './utils';

export interface TopicPageExperimentVariants {
    isExperimentConfigsLoading?: boolean;
    showCodingExercisesBadge?: boolean;
    showUpdatedPPTopicPage?: boolean;
    showPersonalPlanBadge?: boolean;
    showCertificationBanner?: boolean;
    useStandardizeMobileBrowseCarousel?: boolean;
    showSubheadingsInCatSubcatTopicRecommendations?: {
        addNewBeginnerSubheading: boolean;
        addFeaturedCoursesSubheading: boolean;
        addPopularInstructorsSubheading: boolean;
    };
    browseAggregatorPageEnabled?: boolean;
    useVerticalMobileBrowseLayout?: boolean;
    showCourseGuidance?: boolean;
    fullQuickViewBoxFeature?: boolean;
}

export interface TopicProps
    extends TopicContext,
        TopicPageExperimentVariants,
        WithUDDataProps,
        WithI18nProps {
    subsCollectionIds?: string;
    isConsumerSubsSubscriber: boolean;
    isSubsLoading?: boolean;
    // In the server side, withMatchMedia is returning null
    isMobile?: boolean | null;
    certification?: CertificationModel;
    certificationBundle?: CertificationBundle;
    genericTopicBundleCourses?: BrowseCourse[];
    staticDiscoveryUnits?: DiscoveryUnit[];
    enableLabsInPersonalPlan?: boolean;
    enableAssessmentsInPersonalPlan?: boolean;
}

@withMatchMedia({isMobile: 'mobile-max'})
@observer
export class InternalTopic extends React.Component<TopicProps & WithRouterProps> {
    static defaultProps = {
        presetValue: PRESET_ALL,
        relatedPages: [],
        questionsAndAnswers: [],
        showPersonalPlanBadge: false,
        showCodingExercisesBadge: false,
        showUpdatedPPTopicPage: false,
        isMobile: false,
        enableLabsInPersonalPlan: false,
        enableAssessmentsInPersonalPlan: false,
        showCourseGuidance: false,
        fullQuickViewBoxFeature: false,
    };

    async componentDidMount() {
        Tracker.publishEvent(
            new TopicPageViewEvent({
                topicId: getPageObjectIdFromTopic(this.props.topic),
            }),
        );
        this.updatePageContext();
    }

    componentDidUpdate(prevProps: Readonly<TopicProps & WithRouterProps>) {
        if (
            prevProps.udData.Config.marketplace_country?.id !=
            this.props.udData.Config.marketplace_country?.id
        ) {
            this.updatePageContext();
        }
    }

    private ref = React.createRef<HTMLDivElement>();
    @observable discoveryUnitsStore: DiscoveryUnitsContainerStore | undefined;

    private resourceContextMenuProps = {
        context: CONTEXT_TYPES.ALL_COURSES,
    };

    updatePageContext = () => {
        const {udData, topic} = this.props;
        // This trigger braze, it gets the value of marketplace_country from udData.Config
        if (topic.id && udData.Config.marketplace_country?.id) {
            const updatedUDData = {
                marketplace_country: {},
                pageContext: {
                    entityId: topic.id.toString(),
                },
            };

            setTimeout(() => {
                window.dispatchEvent(
                    new CustomEvent(CUSTOM_EVENT_NAME_PROVIDER_BRIDGE, {detail: updatedUDData}),
                );
            }, 0);
        }
    };

    @computed
    get pageType() {
        const {udData, isConsumerSubsSubscriber} = this.props;
        const {Config} = udData;
        const hasOrganization = Config.brand.has_organization;

        let pageType: TopicPageType = PAGE_TYPE_TOPIC;
        if (isConsumerSubsSubscriber) {
            pageType = PAGE_TYPE_SUBS_TOPIC;
        }
        if (hasOrganization) {
            pageType = PAGE_TYPE_ORG_TOPIC;
        }
        return pageType;
    }

    @computed
    get arePageDataLoading() {
        return this.props.isSubsLoading || this.props.udData.isGlobalMeContextLoading;
    }

    @computed
    get showCertificationBanner() {
        return !!this.props.certification;
    }

    @computed
    get showContextualHeader() {
        return !this.showCertificationBanner && !!this.props.tertiaryDescription;
    }

    @computed
    get hasReplacementTitle() {
        return this.showCertificationBanner || this.showContextualHeader;
    }

    get localizedEnrollmentStat() {
        const enrollmentStat = this.props.enrollmentStat;
        if (!enrollmentStat) {
            return enrollmentStat;
        }
        // Strip all non-digit characters from the string.
        // This can be done because we expect it to be a whole number.
        const cleanedEnrollmentStat = enrollmentStat.replace(/\D/g, '');

        try {
            // Even if the enrollment stat is localized already, we want to do it again, to bring it in line with the
            // localization for other items.
            return parseInt(cleanedEnrollmentStat).toLocaleString(
                this.props.locale.replace('_', '-') || 'en-US',
            );
        } catch {
            return enrollmentStat;
        }
    }

    /**
     * Main purpose of this is that discussed below:
     * https://github.com/udemy/frontends-marketplace-experience/pull/1118/files#r1237231100
     */
    get primaryHeading() {
        const {topic, topicTitle, presetValue} = this.props;
        const showOnlyTopicName = isSubsTopicPage(this.pageType) || isOrgTopicPage(this.pageType);
        if (presetValue === PRESET_ALL && showOnlyTopicName) {
            // This is a localized name for the topic
            return topic.name;
        }
        // This is a localized title for the topic
        return topicTitle;
    }

    private getCatAndSubCatData() {
        return this.props.relatedCategoriesAndSubcategories;
    }

    private renderCertificationBanner() {
        if (this.props.certification) {
            /**
             * @remarks
             * We're not waiting for discoveryUnitStore to be initialized or loaded. If we've waited, it could
             * cause whole page to wait on just for the certification banner, just to not have CLS issues that can
             * be caused by the certification banner.
             *
             * Instead of that, we're just rendering the certification banner without waiting and let it render the
             * related categories and subcategories dynamically. So by this; we're not blocking the page load, and
             * we're not losing the CLS score that much (just a little)
             */

            const relatedCatAndSubCat = this.getCatAndSubCatData();
            return (
                <TopicCertificationBanner
                    certification={this.props.certification}
                    enrollmentStat={this.localizedEnrollmentStat}
                    relatedCategoriesAndSubcategoriesData={{
                        items: relatedCatAndSubCat,
                    }}
                    className={'ud-container'}
                    isMobile={!!this.props.isMobile}
                    relatedCertificationBrowseAggregators={
                        this.props.relatedCertificationBrowseAggregators
                    }
                    browseAggregatorPageEnabled={this.props.browseAggregatorPageEnabled}
                />
            );
        }
        return null;
    }

    private renderContextualHeader() {
        const relatedCatAndSubCat = this.getCatAndSubCatData();
        return (
            <ContextualHeader
                className="ud-container"
                title={this.primaryHeading}
                description={this.props.tertiaryDescription ?? ''}
                enrollmentStat={this.localizedEnrollmentStat}
                numCourses={this.props.numCourses}
                numExercises={this.props.numExercises}
                rating={this.props.avgRating}
                relatedCategoriesAndSubcategories={relatedCatAndSubCat}
            />
        );
    }

    private renderTitle() {
        const {isMobile} = this.props;
        return (
            <AlternateHeadline
                title={this.primaryHeading}
                titleTag="h1"
                titleClass={isMobile ? 'ud-heading-serif-xl' : 'ud-heading-serif-xxl'}
                titleStyle={isMobile ? 'title-compact' : 'title'}
                layoutVariant={isMobile ? 'default' : 'large'}
            />
        );
    }

    private renderLoadingTitle() {
        /**
         * The alternate headline in the loading state is for the seo purposes.
         * We're only serving our pages to bots if we're in the mx and not logged in. But title is changing for below
         * user-product types:
         * - Logged out - MX
         * - Logged in - Subs
         * - Logged in - UFB
         *
         * But since we're using same page for those 3 pairs, we need to wait to load the title. But as you can see
         * we only serve Logged out - MX pair to the bots so actually there's only one title that bots can see.
         *
         * That's why, while loading, we're just injecting a screen reader only title for cover the waiting for getting
         * the subscription or ud me data in order to render correct title.
         */
        return (
            <>
                <SkeletonTitle className={styles['title-skeleton']} data-item-index={0} />
                <AlternateHeadline
                    title={this.primaryHeading}
                    className="ud-sr-only"
                    titleTag="h1"
                />
            </>
        );
    }

    private renderDiscoveryUnitsLoader() {
        const {isMobile, staticDiscoveryUnits} = this.props;
        if (this.props.staticDiscoveryUnits?.length ?? 0 > 0) {
            return (
                <>
                    <TopicDiscoveryUnitsContainerWrapper
                        {...this.props}
                        pageType={'topic'}
                        disableInfiniteScroll={true}
                        discoveryUnitFilter={this.discoveryUnitFilter}
                        units={staticDiscoveryUnits}
                        isStaticRender={serverOrClient.isServer}
                    />
                    <SkeletonCourseList courseCount={4} />
                    <SkeletonCourseList courseCount={4} />
                </>
            );
        } else {
            return <TopicDiscoveryUnitsContainerWrapperLoader isMobile={!!isMobile} />;
        }
    }

    private renderCategoryUnits() {
        const relatedCatAndSubCat = this.getCatAndSubCatData();
        if (!relatedCatAndSubCat?.length) return null;

        const {isMobile, enrollmentStat, topic} = this.props;
        return (
            <RelatedCategoriesUnit
                className={
                    enrollmentStat
                        ? styles['related-categories-margin-sm']
                        : styles['related-categories-margin-lg']
                }
                compact={isMobile}
                relatedCategoriesAndSubcategories={relatedCatAndSubCat}
                topicTitle={topic.name}
            />
        );
    }

    private renderCourseGuidanceBanner() {
        if (this.props.showCourseGuidance) {
            return (
                <div className="component-margin" data-item-index={3}>
                    <CourseGuidanceTakeShortAssessmentEntrypoint />
                </div>
            );
        }
        return <></>;
    }
    private renderBundleUnit() {
        const {udData, isConsumerSubsSubscriber} = this.props;
        const {Config} = udData;
        const locale = udData.request.locale;

        const isMXUser = !isConsumerSubsSubscriber && !Config.brand.has_organization;
        const isEnglishLocale = locale === 'en_US';

        // Expanded certification bundle set is only available for MX users in English locale
        const certificationBundleSet = isMXUser && isEnglishLocale ? 'FIRST_EXPANSION' : 'INITIAL';
        const isCertificationBundleInSet =
            this.props.certificationBundle &&
            isBundleInSet(this.props.certificationBundle, certificationBundleSet);

        // Generic bundles are only available for MX users and cert bundles only for MX in English locales
        const showGenericBundles = isMXUser;
        const showCertificationBundle = isMXUser && isEnglishLocale && isCertificationBundleInSet;

        // Cert bundle discount experiment props
        // These will be provided to the bundle unit only for cert bundles
        const certBundleDiscountProps = {
            isBundleDiscountEnabled: true,
            bundleDiscountCode: CERT_BUNDLE_DISCOUNT_CODE,
        };

        if (showCertificationBundle && this.props.certificationBundle) {
            return (
                <div
                    className={classNames(styles['certification-bundle-unit-wrapper'], {
                        'component-margin': !this.hasReplacementTitle,
                    })}
                >
                    <CertificationBundleUnit
                        certificationBundle={this.props.certificationBundle}
                        {...certBundleDiscountProps}
                    />
                </div>
            );
        } else if (showGenericBundles && this.props.genericTopicBundleCourses) {
            return (
                <div
                    className={classNames(styles['certification-bundle-unit-wrapper'], {
                        'component-margin': !this.hasReplacementTitle,
                    })}
                    data-item-index={0}
                >
                    <HorizontalBundleUnit
                        contents={this.props.genericTopicBundleCourses}
                        title={interpolate(
                            gettext(
                                "Looking to advance your skills in %(topicName)s? We've got you.",
                            ),
                            {topicName: this.props.topic.name},
                            true,
                        )}
                        subtitle={gettext(
                            'Get everything you need to reach your goals in one convenient bundle.',
                        )}
                        valueProps={[
                            gettext('Top-rated courses'),
                            gettext('Popular with learners just like you'),
                            gettext('Guidance from real-world experts'),
                        ]}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    get topicEnrollmentStatContent() {
        return !!this.localizedEnrollmentStat ? (
            <TopicEnrollmentStats
                data-item-index={0}
                enrollmentStat={this.localizedEnrollmentStat}
            />
        ) : null;
    }

    get certificationUnitContent() {
        const {udData, topic} = this.props;
        const {Config} = udData;

        const hasOrganizationChina = (Config.brand as BrandWithOrganization)?.organization
            ?.is_enterprise_china;

        const showCertificationUnit =
            isSubsTopicPage(this.pageType) ||
            (isOrgTopicPage(this.pageType) && !hasOrganizationChina);

        return showCertificationUnit ? (
            <CertificationUnit
                data-item-index={0}
                className="component-margin"
                topicId={topic.id}
            />
        ) : null;
    }

    get discoveryListContainerContent() {
        const ref = this.ref;

        const handlePageChange = () => {
            if (serverOrClient.global.scrollY && ref?.current) {
                const y = ref?.current?.getBoundingClientRect().top + serverOrClient.global.scrollY;
                serverOrClient.global.scrollTo?.({
                    top: y,
                    behavior: 'smooth',
                });
            }
        };

        const presetFilters =
            this.props.presetValue === PRESET_FREE
                ? {[AGGREGATION_TYPE_PRICE]: ['price-free']}
                : undefined;

        return (
            <div className="component-margin" data-item-index={-2} ref={this.ref}>
                <TopicDiscoveryListContainerWrapper
                    {...this.props}
                    pageType={this.pageType}
                    presetFilters={presetFilters}
                    handlePageChange={handlePageChange}
                />
            </div>
        );
    }

    get learnMoreSectionContent() {
        const {
            presetValue,
            primaryDescription,
            questionsAndAnswers,
            relatedPages,
            topic,
            isMobile,
            gettext,
            interpolate,
        } = this.props;

        const relatedPagesHeadline = {
            title: interpolate(
                gettext('Free %(topicName)s lessons'),
                {topicName: topic.name},
                true,
            ),
            secondaryText: gettext('Bite-sized learning in minutes'),
            className: styles['section-container'],
            layoutVariant: isMobile ? 'compact' : 'default',
        };

        return (
            <div className={styles['learn-more-section-container']}>
                <LearnMoreSection
                    className={styles['learn-more-section']}
                    topic={topic}
                    primaryDescription={primaryDescription}
                    questionsAndAnswers={questionsAndAnswers}
                    relatedPagesHeadline={{
                        ...relatedPagesHeadline,
                    }}
                    relatedPages={relatedPages}
                    presetValue={presetValue}
                    isMobile={!!isMobile}
                />
            </div>
        );
    }

    discoveryUnitFilter = (unit: Partial<DiscoveryUnit>) => {
        const {presetValue, enableLabsInPersonalPlan, enableAssessmentsInPersonalPlan} = this.props;
        const {Config, me} = this.props.udData;
        const isUBUser = me.is_authenticated && !!me.organization;
        const isUBProUser =
            isUBUser &&
            (Config.features.organization.learning_path.pro_path ||
                (me.is_authenticated && me.organization?.is_pro_license_holder));

        const shouldShowLabs =
            (isSubsTopicPage(this.pageType) && enableLabsInPersonalPlan) || isUBProUser;
        const shouldShowAssessments =
            (isSubsTopicPage(this.pageType) && enableAssessmentsInPersonalPlan) || isUBProUser;

        if (presetValue === PRESET_FREE) {
            return unit.type === 'bestseller_labels';
        }

        /**
         * Currently, if we're using certification banner, we're already showing the related_categories_and_subcategories
         * results inside of it, so we need to filter out from the discovery units container
         * ˝
         * TODO: remove this condition after the related categories and subcategories are removed from the DiscoveryAPI
         */
        if (unit.type === 'related_categories_and_subcategories') {
            return false;
        }

        if (!shouldShowLabs) {
            return unit?.item_type !== 'lab';
        }
        if (!shouldShowAssessments) {
            return unit?.item_type !== 'assessment';
        }

        // no filter
        return true;
    };

    @action
    getOrInitializeDiscoveryUnitStore = (initializationParam?: {
        pageType: string;
        pageObjectId: number;
    }) => {
        if (this.discoveryUnitsStore) {
            return this.discoveryUnitsStore;
        }
        if (!initializationParam) {
            return undefined;
        }
        const {pageType, pageObjectId} = initializationParam;
        this.discoveryUnitsStore = new DiscoveryUnitsContainerStore({pageType, pageObjectId});
        if (!this.discoveryUnitsStore.loading && this.discoveryUnitsStore.firstLoad) {
            this.discoveryUnitsStore.fetchUnits({pageSize: 50});
        }
        return this.discoveryUnitsStore;
    };

    render() {
        const {primaryDescription, questionsAndAnswers, relatedPages, topic} = this.props;

        const showLearnMoreSection =
            primaryDescription || relatedPages.length > 1 || questionsAndAnswers.length > 0;

        const pageObjectId = getPageObjectIdFromTopic(topic);

        const {isConsumerSubsSubscriber, showPersonalPlanBadge, showCodingExercisesBadge} =
            this.props;

        /**
         * Wait for page data to be loaded before initializing discovery store since it depends on pageType and
         * pageType depends on page data (subs and uddata)
         */
        if (!this.arePageDataLoading) {
            this.getOrInitializeDiscoveryUnitStore({
                pageType: this.pageType,
                pageObjectId,
            });
        }

        const isDiscoveryUnitsLoading =
            this.arePageDataLoading || this.getOrInitializeDiscoveryUnitStore()?.firstLoad;

        /**
         * Provider of Mobx requires immutable props, in order to avoid below problem, don't forget to wait for
         * page data to be loaded or just don't wrap with it until the page data is loaded.
         * "MobX Provider: The set of provided stores has changed." error in loading stage.
         * https://unpkg.com/browse/mobx-react@7.2.1/src/Provider.tsx
         */
        const mobxProviderProps = {
            isConsumerSubsSubscriber,
            showPersonalPlanBadge,
            showCodingExercisesBadge,
        };
        return (
            <>
                {this.showCertificationBanner && this.renderCertificationBanner()}
                {this.showContextualHeader && this.renderContextualHeader()}
                <div className="ud-container ud-page-wrapper">
                    {!this.arePageDataLoading
                        ? !this.hasReplacementTitle && this.renderTitle()
                        : !this.hasReplacementTitle && this.renderLoadingTitle()}
                    {(this.hasReplacementTitle || isDiscoveryUnitsLoading) &&
                        this.renderBundleUnit()}
                    {!this.hasReplacementTitle && this.renderCategoryUnits()}
                    {this.renderCourseGuidanceBanner()}
                    {!isDiscoveryUnitsLoading ? (
                        <Provider {...mobxProviderProps}>
                            <ResourceContextMenuProvider
                                resourceContextMenuProps={this.resourceContextMenuProps}
                            >
                                <TopicDiscoveryUnitsContainerWrapper
                                    {...this.props}
                                    store={this.getOrInitializeDiscoveryUnitStore({
                                        pageType: this.pageType,
                                        pageObjectId,
                                    })}
                                    pageType={this.pageType}
                                    discoveryUnitFilter={this.discoveryUnitFilter}
                                    fetchOptions={{
                                        /**
                                         * This is a temporary solution for handling the infinite scroll with the
                                         * children that injected from outside. So rather than using the infinite scroll
                                         * and fetching more units after the last unit, we're just fetching the units
                                         * all together and showing them in the page.
                                         *
                                         * Problem in here is that, we're injecting a unit from outside to as last unit,
                                         * and we're trying to fetch a new unit in the middle of the page.
                                         */
                                        pageSize: 50,
                                    }}
                                >
                                    {!this.hasReplacementTitle && this.topicEnrollmentStatContent}
                                    {!this.hasReplacementTitle && this.renderBundleUnit()}
                                    {this.certificationUnitContent}
                                    {this.discoveryListContainerContent}
                                    <ValueProps className="component-margin" />
                                </TopicDiscoveryUnitsContainerWrapper>
                            </ResourceContextMenuProvider>
                        </Provider>
                    ) : (
                        this.renderDiscoveryUnitsLoader()
                    )}

                    {showLearnMoreSection && (
                        <MarketplaceOnly>{this.learnMoreSectionContent}</MarketplaceOnly>
                    )}
                </div>
            </>
        );
    }
}

export const Topic = withI18n(withUDData(withRouter(InternalTopic)));
