import {UIRegionOptions} from 'udemy-django-static/js/browse/ui-regions';
import {ClientEvent, Tracker as tracker} from '@udemy/event-tracking';

/**
 This event is fired when a user interacts with flow progressing elements in
 the Occupation exploration process
 TODO: OccupationFlowProgressionEvent will deprecate after atomic level implementation is complete.
 */
interface OccupationFlowProgressionEventProps {
    progression: number;
    selection: string;
    selectionType: string;
}
export class OccupationFlowProgressionEvent extends ClientEvent {
    progression: number;
    selection: string;
    selectionType: string;
    constructor({progression, selection, selectionType}: OccupationFlowProgressionEventProps) {
        super('OccupationFlowProgressionEvent');
        this.progression = progression;
        this.selection = selection;
        this.selectionType = selectionType;
    }
}

interface OccupationGoalHeaderEventProps {
    careerGoal?: string;
    occupationName?: string;
    occupationId?: number;
}
/**
 Fires when user sees user's occupation goal header component.
 */
export class OccupationGoalHeaderImpressionEvent extends ClientEvent {
    careerGoal?: string;
    occupationName?: string;
    occupationId?: number;
    constructor({careerGoal, occupationName, occupationId}: OccupationGoalHeaderEventProps) {
        super('OccupationGoalHeaderImpressionEvent');
        this.careerGoal = careerGoal;
        this.occupationName = occupationName;
        this.occupationId = occupationId;
    }
}

interface OccupationFlowEditEventProps {
    currentOccupationId?: number;
    currentOccupationName?: string;
    currentCareerGoal?: string;
    uiRegion: string;
}
/**
 Fires when a user clicks the "Edit goal" button on the occupation header component or
"Edit profession" button on the recommendation unit.
*/
export class OccupationFlowEditEvent extends ClientEvent {
    currentOccupationId?: number;
    currentOccupationName?: string;
    currentCareerGoal?: string;
    uiRegion: string;
    constructor({
        currentOccupationId,
        currentOccupationName,
        currentCareerGoal,
        uiRegion,
    }: OccupationFlowEditEventProps) {
        super('OccupationFlowEditEvent');
        this.currentOccupationId = currentOccupationId;
        this.currentOccupationName = currentOccupationName;
        this.currentCareerGoal = currentCareerGoal;
        this.uiRegion = uiRegion;
    }
}

interface OccupationFlowStartEventProps {
    nonInteraction: boolean;
}

export class OccupationFlowStartEvent extends ClientEvent {
    nonInteraction: boolean;
    constructor({nonInteraction}: OccupationFlowStartEventProps) {
        super('OccupationFlowStartEvent');
        this.nonInteraction = nonInteraction;
    }
}

export type OccupationDataFromOption = 'OCF' | 'OLP';
export type CareerGoalOption =
    | 'occupation-Aspiring'
    | 'occupation-Advancing'
    | 'management-Aspiring'
    | 'management-Advancing';

/**
    This events is fired when user sees user's occupation specific topic unit.
    Note: User's occupation is determined either by user submitted data in occupation collection flow or
    via the occupation landing page via which user converted to personal plan.
 */
interface OccupationTopicUnitImpressionEventProps {
    occupationId: number;
    occupationName: string;
    careerGoal?: CareerGoalOption;
    occupationDataFrom: OccupationDataFromOption;
}
export class OccupationTopicUnitImpressionEvent extends ClientEvent {
    occupationId: number;
    occupationName: string;
    careerGoal: CareerGoalOption | undefined;
    occupationDataFrom: OccupationDataFromOption;
    constructor({
        occupationId,
        occupationName,
        careerGoal,
        occupationDataFrom,
    }: OccupationTopicUnitImpressionEventProps) {
        super('OccupationTopicUnitImpressionEvent');
        this.occupationId = occupationId;
        this.occupationName = occupationName;
        this.careerGoal = careerGoal;
        this.occupationDataFrom = occupationDataFrom;
    }
}

/**
    This events if fired when user clicks on any one of the topic/skills in occupation specific topic unit.
    Note: User's occupation is determined either by user submitted data in occupation collection flow or
    via the occupation landing page via which user converted to personal plan.
 */
interface OccupationTopicUnitSkillClickEventProps {
    occupationId: number;
    occupationName: string;
    topicId: number;
    topicName: string;
    careerGoal?: CareerGoalOption;
    occupationDataFrom: OccupationDataFromOption;
}
export class OccupationTopicUnitSkillClickEvent extends ClientEvent {
    occupationId: number;
    occupationName: string;
    topicId: number;
    topicName: string;
    careerGoal: CareerGoalOption | undefined;
    occupationDataFrom: OccupationDataFromOption;
    constructor({
        occupationId,
        occupationName,
        topicId,
        topicName,
        careerGoal,
        occupationDataFrom,
    }: OccupationTopicUnitSkillClickEventProps) {
        super('OccupationTopicUnitSkillClickEvent');
        this.occupationId = occupationId;
        this.occupationName = occupationName;
        this.topicId = topicId;
        this.topicName = topicName;
        this.careerGoal = careerGoal;
        this.occupationDataFrom = occupationDataFrom;
    }
}
