import React from 'react';

import {StudentQuote, CourseReviewProps} from './student-quote.react-component';

interface StudentQuoteUnitProps {
    topicTitle: string;
    courseReview?: CourseReviewProps;
}

export const StudentQuoteUnit = ({topicTitle, courseReview}: StudentQuoteUnitProps) => {
    if (!courseReview) {
        return null;
    }

    return (
        <div data-purpose="student-quote-unit-wrapper">
            <StudentQuote courseReview={courseReview} topicTitle={topicTitle} />
        </div>
    );
};
