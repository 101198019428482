import classNames from 'classnames';
import React from 'react';

import {
    BreadCrumb,
    BrowsePageContentUtil,
    filterIssuerAggregatorPages,
    filterSubjectAggregatorPages,
    NavigationBreadcrumbs,
} from '@udemy/browse-aggregator';
import {
    BrowseNavigationItemSelected,
    BrowseNavigationItemStyle,
    BrowseNavigationItemType,
} from '@udemy/browse-event-tracking';
import {DiscoveryUnit} from '@udemy/discovery-api';
import {Tracker} from '@udemy/event-tracking';
import {useI18n} from '@udemy/i18n';
import {
    CertificationPreparationLearnMoreSelected,
    CertificationPrepBanner,
    CertificationPrepBannerProps,
    CertificationPrepLearnMoreModal,
} from '@udemy/react-certification-preparation';
import {Button} from '@udemy/react-core-components';

import {CertificationModel} from 'udemy-django-static/js/open-badges/certification.mobx-model';

import {TopicPageExperimentVariants} from '../topic.react-component';
import {TopicContext} from '../types/topic';
import styles from './topic-certification-banner.module.less';

export type TopicCertificationBannerProps = {
    certification: CertificationModel;
    relatedCategoriesAndSubcategoriesData?: Partial<
        DiscoveryUnit<{
            title: string;
            url: string;
        }>
    >;
    isMobile: boolean;
} & Omit<CertificationPrepBannerProps, 'certification' | 'relatedLinks'> &
    Pick<TopicContext, 'relatedCertificationBrowseAggregators'> &
    Pick<TopicPageExperimentVariants, 'browseAggregatorPageEnabled'>;

export const TopicCertificationBanner = (props: TopicCertificationBannerProps) => {
    if (props.browseAggregatorPageEnabled) {
        return <TopicCertificationBannerWithAggregator {...props} />;
    }
    return <TopicCertificationBannerWithoutAggregator {...props} />;
};

const renderLearnMoreButton = () => (
    <Button
        componentClass="a"
        className={'ud-text-sm'}
        udStyle="link-underline"
        onClick={() =>
            Tracker.publishEvent(
                new CertificationPreparationLearnMoreSelected({
                    uiRegion: 'certification_banner',
                }),
            )
        }
    >
        {gettext('Learn more')}
    </Button>
);

export const TopicCertificationBannerWithoutAggregator = (props: TopicCertificationBannerProps) => {
    const {gettext, interpolate, lang} = useI18n();
    const {className, isMobile, relatedCategoriesAndSubcategoriesData, enrollmentStat} = props;

    /**
     * Currently, certification data is not translated. It's only available for english. In order to prevent
     * showing the certification data in other languages, we're just removing the description field from the
     * non english locales.
     *
     * For more info please check here: https://udemy.slack.com/archives/C04JG4VBW0G/p1712066026063029
     */
    let certificationData = props.certification;

    if (lang !== 'en') {
        certificationData = {
            ...certificationData,
            description: '',
        } as CertificationModel;
    }

    // TODO: Please update this on the banner component, not here. Can break downstream in the future
    const certificationProps = {
        issuer: certificationData.issuer.name,
        name: interpolate(
            gettext('%(certificationName)s Courses'),
            {
                certificationName: certificationData.name,
            },
            true,
        ),
        description: certificationData.description,
        image: certificationData.image.id,
    };

    const getRelatedLinks = (
        relatedCategoriesAndSubcategoriesData: TopicCertificationBannerProps['relatedCategoriesAndSubcategoriesData'],
    ) => {
        const mostRelevantItems = (relatedCategoriesAndSubcategoriesData?.items ?? []).slice(0, 2);

        if (mostRelevantItems.length === 0) {
            return;
        }

        const title = gettext('Related topics');
        const links = mostRelevantItems.map((item) => ({
            name: item.title,
            url: item.url,
        }));
        return {title, links};
    };

    const relatedLinks = relatedCategoriesAndSubcategoriesData
        ? getRelatedLinks(relatedCategoriesAndSubcategoriesData)
        : undefined;

    return (
        <div className={styles['fill-background']}>
            <CertificationPrepBanner
                className={classNames(className, styles['fill-background'])}
                certification={certificationProps}
                enrollmentStat={enrollmentStat}
                relatedLinks={relatedLinks}
                showMoreProps={{
                    moreButtonLabel: gettext('Read more'),
                    lessButtonLabel: gettext('Read less'),
                }}
                learnMore={
                    <CertificationPrepLearnMoreModal
                        trigger={renderLearnMoreButton()}
                        isMobile={isMobile}
                    />
                }
            />
        </div>
    );
};

export const TopicCertificationBannerWithAggregator = (props: TopicCertificationBannerProps) => {
    const {gettext, interpolate, lang} = useI18n();
    const {className, isMobile, enrollmentStat, relatedCertificationBrowseAggregators} = props;

    /**
     * Currently, certification data is not translated. It's only available for english. In order to prevent
     * showing the certification data in other languages, we're just removing the description field from the
     * non english locales.
     *
     * For more info please check here: https://udemy.slack.com/archives/C04JG4VBW0G/p1712066026063029
     */
    let certificationData = props.certification;

    if (lang !== 'en') {
        certificationData = {
            ...certificationData,
            description: '',
        } as CertificationModel;
    }

    // TODO: Please update this on the banner component, not here. Can break downstream in the future
    const certificationProps = {
        issuer: certificationData.issuer.name,
        name: interpolate(
            gettext('%(certificationName)s Courses'),
            {
                certificationName: certificationData.name,
            },
            true,
        ),
        description: certificationData.description,
        image: certificationData.image.id,
    };

    const issuerAggregator = filterIssuerAggregatorPages(
        relatedCertificationBrowseAggregators,
    )?.[0];

    const subjectAggregators = filterSubjectAggregatorPages(
        relatedCertificationBrowseAggregators,
    ).slice(0, 2);

    let breadcrumbs: BreadCrumb[] = [];
    const aggregatorPageNavInfo =
        issuerAggregator && new BrowsePageContentUtil(issuerAggregator).getNavInfo();
    if (issuerAggregator && aggregatorPageNavInfo && certificationData.issuer.name) {
        breadcrumbs = [
            {
                id: 1,
                title: gettext('Certification preparation'),
                url: '/browse/certification/',
                onClick: () => {
                    Tracker.publishEvent(
                        new BrowseNavigationItemSelected({
                            id: '0',
                            type: BrowseNavigationItemType.CERTIFICATION_PREPARATION,
                            uiRegion: 'related_categories_unit',
                            style: BrowseNavigationItemStyle.LINK,
                        }),
                    );
                },
            },
            {
                id: 2,
                title: aggregatorPageNavInfo.title,
                url: aggregatorPageNavInfo.url,
                onClick: () => {
                    Tracker.publishEvent(
                        new BrowseNavigationItemSelected({
                            id: aggregatorPageNavInfo.assignedDescriptorId,
                            type: BrowseNavigationItemType.CERTIFICATION_ISSUER_AGGREGATOR,
                            uiRegion: 'related_categories_unit',
                            style: BrowseNavigationItemStyle.LINK,
                        }),
                    );
                },
            },
            {
                id: 3,
                title: certificationData.name,
            },
        ];
    }

    let relatedLinks: CertificationPrepBannerProps['relatedLinks'];
    if (subjectAggregators?.length > 0) {
        relatedLinks = {
            title: gettext('Related topics'),
            links: subjectAggregators
                .map((subjectAggregator) => {
                    const navInfo =
                        subjectAggregator &&
                        new BrowsePageContentUtil(subjectAggregator).getNavInfo();
                    if (!navInfo) {
                        return;
                    }
                    return {
                        name: navInfo?.title,
                        url: navInfo?.url,
                        onClick: () => {
                            Tracker.publishEvent(
                                new BrowseNavigationItemSelected({
                                    id: navInfo.assignedDescriptorId,
                                    type: BrowseNavigationItemType.CERTIFICATION_SUBJECT_AGGREGATOR,
                                    uiRegion: 'related_categories_unit',
                                    style: BrowseNavigationItemStyle.LINK,
                                }),
                            );
                        },
                    };
                })
                .filter((k) => k !== undefined),
        };
    }

    return (
        <div className={styles['fill-background']}>
            <div className={className}>
                {breadcrumbs.length > 0 && (
                    <NavigationBreadcrumbs
                        breadcrumbs={breadcrumbs}
                        className={styles['nav-breadcrumbs']}
                    />
                )}
                <CertificationPrepBanner
                    className={classNames(
                        styles['fill-background'],
                        styles['no-padding-on-left-and-right'],
                    )}
                    certification={certificationProps}
                    enrollmentStat={enrollmentStat}
                    relatedLinks={relatedLinks}
                    showMoreProps={{
                        moreButtonLabel: gettext('Read more'),
                        lessButtonLabel: gettext('Read less'),
                    }}
                    learnMore={
                        <CertificationPrepLearnMoreModal
                            trigger={renderLearnMoreButton()}
                            isMobile={isMobile}
                        />
                    }
                />
            </div>
        </div>
    );
};
