import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {Block, Skeleton} from '@udemy/react-reveal-components';
import range from 'udemy-django-static/js/utils/range';

import styles from './course-card-skeleton.module.less';

export default class CourseCardSkeleton extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        width: PropTypes.oneOf(['fixed', 'flexible']),
        imageStyle: PropTypes.object,
        lineCount: PropTypes.number,
    };

    static defaultProps = {
        className: null,
        imageStyle: null,
        lineCount: 3,
        size: 'medium',
        width: 'flexible',
    };

    render() {
        const {className, imageStyle, lineCount, size, width, ...htmlProps} = this.props;
        return (
            <Skeleton
                {...htmlProps}
                data-purpose="course-card-skeleton"
                className={classNames(className, {
                    [styles['skeleton-fixed']]: width === 'fixed',
                    [styles['skeleton-flexible']]: width === 'flexible',
                    [styles['skeleton-small']]: size === 'small',
                    [styles['skeleton-medium']]: size === 'medium',
                    [styles['skeleton-large']]: size === 'large',
                })}
            >
                <Block style={imageStyle} className={styles['image']} />
                <div style={{flex: 1}}>
                    <Block className={styles['title']} />
                    {range(lineCount).map((i) => (
                        <Block key={i} className={styles['line']} />
                    ))}
                </div>
            </Skeleton>
        );
    }
}
