import {useRouter} from 'next/router';
import {useCallback, useEffect, useMemo} from 'react';

import {useCanonicalUrl} from '@udemy/head-seo';
import {setupNavigation} from '@udemy/react-discovery-units';

import {META_DEFAULT_BASE_URL} from '../lib/constants';

export const useSetupNavigation = () => {
    const router = useRouter();
    const {url: canonicalUrl} = useCanonicalUrl(
        router as {locale: string; asPath: string},
        [],
        META_DEFAULT_BASE_URL,
    );
    const setup = useCallback(() => {
        setupNavigation(() => {
            const history = {
                ...globalThis.history,
                pushState: (
                    data: unknown,
                    unused: string,
                    url: string | URL | null | undefined,
                ) => {
                    if (!url) {
                        throw new Error(
                            'The url parameter passed to pushState cannot be undefined or null.',
                        );
                    }
                    const urlObj = new URL(url, canonicalUrl);
                    router.push(urlObj, undefined, {scroll: false, shallow: true});
                },
            };
            return [history, globalThis.location ?? {}];
        });
    }, [canonicalUrl, router]);

    useMemo(() => setup(), [setup]);

    useEffect(() => {
        setup();
    }, [setup]);
};
