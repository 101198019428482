import React from 'react';

import {AboveTheFoldProvider} from '@udemy/design-system-utils';

export const withAboveTheFoldProvider = <P extends object>(
    Component: React.ComponentType<P>,
): React.FC<P> => {
    return (props: P) => (
        <AboveTheFoldProvider>
            <Component {...props} />
        </AboveTheFoldProvider>
    );
};
