import classNames from 'classnames';
import React from 'react';

import {withI18n, WithI18nProps} from '@udemy/i18n';
import {QuestionAndAnswer, QuestionsAndAnswers} from '@udemy/react-frequently-asked-questions';

import {Topic} from 'src/generated/graphql';
import {ComponentProps} from 'udemy-django-static/js/utils/types';

import {TOPIC_PRESET_VALUES} from '../constants';
import {RelatedPage} from '../types/topic';
import styles from './learn-more-section.module.less';
import {RelatedPages, TopicPageLink} from './related-pages.react-component';

interface LearnMoreSectionProps extends WithI18nProps {
    className?: string;
    topic: Pick<Topic, 'name'>;
    primaryDescription?: string;
    questionsAndAnswers: QuestionAndAnswer[];
    relatedPagesHeadline: ComponentProps<typeof RelatedPages>['alternateHeadline'];
    relatedPages: RelatedPage[];
    presetValue: string;
    isMobile: boolean;
    gettext: WithI18nProps['gettext'];
    interpolate: WithI18nProps['interpolate'];
}

export class InternalLearnMoreSection extends React.Component<LearnMoreSectionProps> {
    render() {
        const {
            className,
            topic,
            primaryDescription,
            questionsAndAnswers,
            relatedPagesHeadline,
            relatedPages,
            presetValue,
            isMobile,
            gettext,
            interpolate,
        } = this.props;

        return (
            <div className={classNames(className, styles.container)}>
                <div>
                    <h2
                        className={classNames(
                            isMobile ? 'ud-heading-xl' : 'ud-heading-xxl',
                            styles.title,
                        )}
                        data-purpose="title"
                    >
                        {interpolate(
                            gettext('Learn more about %(topicName)s'),
                            {topicName: topic.name},
                            true,
                        )}
                    </h2>
                    {this.props.primaryDescription && (
                        <p
                            className={classNames(
                                isMobile ? 'ud-text-sm' : 'ud-text-md',
                                styles['primary-description'],
                            )}
                            data-purpose="primary-description"
                        >
                            {primaryDescription}
                        </p>
                    )}
                    {this.props.relatedPages.length === 2 && (
                        <div className={styles['free-topic-page-link']}>
                            <TopicPageLink
                                presetValue={TOPIC_PRESET_VALUES.FREE}
                                topicTitle={this.props.topic.name}
                                url={this.props.relatedPages[1].url}
                            />
                        </div>
                    )}
                </div>
                {questionsAndAnswers.length > 0 && (
                    <QuestionsAndAnswers
                        questionsAndAnswers={questionsAndAnswers}
                        compact={isMobile}
                        defaultExpanded={true}
                    />
                )}
                {this.props.relatedPages.length > 2 && (
                    <RelatedPages
                        alternateHeadline={relatedPagesHeadline}
                        isMobile={isMobile}
                        presetValue={presetValue}
                        relatedPages={relatedPages}
                        topic={topic}
                    />
                )}
            </div>
        );
    }
}

export const LearnMoreSection = withI18n(InternalLearnMoreSection);
