import React from 'react';

import {SchemaMarkup as MarkupType} from 'src/components/schema-markup/types';

export const SchemaMarkup = ({schemaMarkup}: {schemaMarkup: MarkupType}) => {
    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{__html: JSON.stringify(schemaMarkup)}}
        />
    );
};
