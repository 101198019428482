import {CertificateDetailLinkClickEvent} from './events/certification-detail-link-click-event';
import {CertificateSelectEvent} from './events/certification-listing-page-select-event';
import {CertificateSearchEvent} from './events/certification-search-filter-event';

export const OPEN_BADGES_BASE_PATH = '/open-badges';
export const ISSUER_FILTER_KEY = 'issuer';
export const SUBJECT_AREA_FILTER_KEY = 'subject_area';
export const DEFAULT_API_PAGE_SIZE = 12;

export const EVENT_LABEL = {
    SEARCH_CERTIFICATE: 'CertificationSearchEvent',
    SELECT_CERTIFICATE_ON_LISTING_PAGE: 'CertificationListingPageSelectEvent',
    SELECT_CERTIFICATE_ON_TOPIC_PAGE: 'CertificationTopicWidgetSelectEvent',
    CLICK_CERTIFICATE_DETAIL_LINK: 'CertificationDetailLinkClickEvent',
} as const;

export const CERTIFICATE_TRACKING_EVENTS = {
    CERTIFICATE_SEARCH_EVENT: CertificateSearchEvent,
    CERTIFICATE_SELECT_EVENT: CertificateSelectEvent,
    CLICK_CERTIFICATE_DETAIL_LINK: CertificateDetailLinkClickEvent,
};

export const ALLOWED_SUBJECT_AREAS = [
    'Accounting',
    'Agile/Scrum',
    'Application Development',
    'Business Intelligence',
    'Cloud',
    'Computer Programming & Development',
    'Customer Relationship Management',
    'Data Analysis',
    'Data Centers',
    'Data Science',
    'Databases',
    'Enterprise Architecture',
    'Enterprise Resource Planning',
    'Finance',
    'Healthcare',
    'Human Resources',
    'Information Privacy & Security',
    'Information Technology',
    'Networking',
    'Office Productivity',
    'Operating Systems',
    'Platform Development',
    'Project Management',
    'Security',
    'Servers',
    'Software Testing',
    'Supply Chain Management',
    'System Administration',
    'Technical Training',
];
